import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "@mantine/core";
import { Image } from "@mantine/core";
import CALENDER from "../../assets/images/calender.png";
import SHAPE from "../../assets/images/Shape.png";
import { Calendar } from "@mantine/dates";

export const AttendenceCalender = ({ onDateSelect, passingDate }) => {
  const currentDate = new Date();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(currentDate);

  useEffect(() => {
    onDateSelect(currentDate);
  }, []);

  useEffect(() => {
    if (passingDate) {
      setSelectedDate(passingDate);
    }
  }, [passingDate]);

  const handleApplyClick = () => {
    setIsPopoverOpen(false);
    onDateSelect(selectedDate);
  };

  const handleCancelClick = () => {
    setIsPopoverOpen(false);
    setSelectedDate(null);
  };

  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDayClick = (day) => {
    setSelectedDate(day);
  };

  const renderCalenderContent = () => {
    return (
      <>
        {isPopoverOpen && (
          <div className="custom-popover" onClick={handlePopoverClick}>
            <div className="attendence-calender">
              <Calendar
                allowLevelChange={false}
                renderDay={(day) => {
                  const isSelected =
                    selectedDate &&
                    day.toDateString() === selectedDate.toDateString();
                  return (
                    <div
                      className={`day-cell ${isSelected ? "selected" : ""}`}
                      onClick={() => handleDayClick(day)}
                    >
                      {day.getDate()}
                    </div>
                  );
                }}
                className="calender-new"
              />
            </div>
            <div className="popover-buttons">
              <Button
                onClick={handleCancelClick}
                style={{ backgroundColor: "#E7EAEE", color: "#101010" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleApplyClick}
                style={{ backgroundColor: "#75B121" }}
              >
                <Image src={SHAPE} width={12} height={12} />
                &nbsp; Apply
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className="calender-box"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        style={{ cursor: "pointer" }}
      >
        {passingDate ? (
          <div className="selected-month">{formatDate(passingDate)}</div>
        ) : selectedDate ? (
          <div className="selected-month">{formatDate(selectedDate)}</div>
        ) : (
          <div className="selected-month">{formatDate(currentDate)}</div>
        )}

        <div className="calender-image">
          <Image src={CALENDER} p={8} />
        </div>
        {renderCalenderContent()}
      </div>
    </>
  );
};

AttendenceCalender.propTypes = {
  onDateSelect: PropTypes.func.isRequired,
  passingDate: PropTypes.instanceOf(Date),
};
