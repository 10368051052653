import {
  Avatar,
  Button,
  Card,
  Center,
  Grid,
  Loader,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomModal from "../../../component/common/modal";
import { roomStatusMapper } from "../../../constant/constant";
import { ChannelInterface } from "../../../shared/interface";
import CreateChannel from "../component/createRoom";
import { createChannel, fetchChannels } from "../services/services";
import { StatusChip, UsersChip } from "../../shared components/statusChip";


export default function Rooms() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allRooms, setRooms] = useState<ChannelInterface[]>([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [actionLoading, setActionLoading] = useState(false);
  const workspace_id = localStorage.getItem("workspace_id");

  const fetchRooms = () => {
    setIsLoading(true);
    if (workspace_id) {
      const params = {
        page: 1,
        workspace_id,
      };
      fetchChannels(params)
        .then((rooms: any) => {
          setIsLoading(false);
          setRooms(rooms);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Error : ", error);
        });
    }
  };

  useEffect(fetchRooms, []);

  // useEffect(() => {
  //   const localStorageRooms = JSON.parse(localStorage.getItem("rooms") || "[]");
  //   if (localStorageRooms.length > 0) {
  //     setRooms(localStorageRooms);
  //   } else {
  //     localStorage.setItem("rooms", JSON.stringify(rooms));
  //   }
  // }, []);

  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = ({ name }: { name: string }) => {
    // const workspace_id =
    //  localStorage.getItem("workspace_id") ;
    setActionLoading(true);
    const params = {
      name,
      type: "group",
      workspace_id: workspace_id,
    };
    createChannel(params)
      .then((channel: any) => {
        setActionLoading(false);
        setRooms([channel, ...allRooms]);
        toggleModal();
      })
      .catch((err) => {
        console.log("err", err);
        setActionLoading(false);
        toggleModal();
      });
    // const id = uuidv4();
    // const newRoom: Room = {
    //   id,
    //   name: room.name,
    //   avatar: "https://img.icons8.com/color/12x/room.png",
    //   status: 1,
    //   users: [],
    // };
    // setRooms([newRoom, ...allRooms]);
    // localStorage.setItem("rooms", JSON.stringify([newRoom, ...allRooms]));
    // setTimeout(() => {
    //   navigate(`/channels/${id}`);
    // }, 200);
  };

  const renderManageButton = (element) => {
    return (
      <Button
        title="Manage"
        variant="filled"
        color={"green.8"}
        px={"5px"}
        pt="3px"
        style={{ width: "100%" }}
        size="xs"
        onClick={() => {
          navigate(`${element.channel_id}`);
        }}
      >
        Manage
      </Button>
    );
  };

  const rows = allRooms
    .filter((row) => {
      if (search === "") {
        return row;
      } else if (row.group_name.toLocaleLowerCase().includes(search)) {
        return row;
      }
    })
    .map((element, index) => (
      <tr key={element.channel_id}>
        <td>
          <Grid my={"2px"} className="flex align-center">
            <Grid.Col span={"content"}>
              <Avatar
                src={
                  element.group_icon ||
                  "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                }
                alt="User Profile"
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50px",
                }}
              />
            </Grid.Col>
            <Grid.Col span={9}>
              <Text size={"sm"}>{element.group_name}</Text>
              <div className="show-in-mobile-only">
                <div className="channel-chip" style={{ marginTop: "5px" }}>
                  <StatusChip
                    status={element.live === 1 ? "Live" : "Non Live"}
                  />
                  <UsersChip users={element.participants?.length} />
                </div>
              </div>
            </Grid.Col>
            <div style={{ width: "100%" }} className="show-in-mobile-only">
              <div className="manage-button">
                {" "}
                {renderManageButton(element)}
              </div>
            </div>
          </Grid>
        </td>
        <td style={{ width: "20%" }} className="hide-in-mobile">
          <Text size={"sm"}>{element.participants?.length}</Text>
        </td>
        <td className="hide-in-mobile">
          <Text size={"sm"}>{roomStatusMapper[element.live || 0]}</Text>
        </td>
        <td className="hide-in-mobile">{renderManageButton(element)}</td>
      </tr>
    ));

  return (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
    >
      <CustomModal
        open={isModalOpen}
        className="create-new-room"
        centered
        title={"Create A New Channel"}
        closeModal={toggleModal}
      >
        <CreateChannel
          closeModal={toggleModal}
          actionLoading={actionLoading}
          onSubmit={onSubmit}
        />
      </CustomModal>
      <Grid my={"2px"} gutter={"xs"}>
        <Grid.Col sm={6} md={4} lg={4}>
          <TextInput
            autoFocus
            placeholder="Search channel"
            icon={<i className="bx bx-search-alt" />}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </Grid.Col>
        <Grid.Col sm={0} md={4} lg={6}></Grid.Col>
        <Grid.Col sm={6} md={4} lg={2}>
          <Button
            variant="gradient"
            fullWidth
            onClick={toggleModal}
            leftIcon={
              <i
                className="bx bx-plus"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              />
            }
          >
            New Channel
          </Button>
        </Grid.Col>
      </Grid>
      <Table>
        <thead>
          <tr>
            <th>Channel Name</th>
            <th className="hide-in-mobile">Users</th>
            <th className="hide-in-mobile">Status</th>
            <th className="hide-in-mobile">Actions</th>
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={4}>
                <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                  <Loader variant="bars" />
                </Center>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </Table>
    </Card>
  );
}
