import { API_CONFIG } from "../../../constant/constant";
import {
  deleteWorkspaceReq,
  workspaceActivateReq,
} from "../../../features/roomSettings/service/settingService";
import { error, fetchWorkSpaces, workspaceStatus } from "./actions";
import HttpService from "../../../shared/services/http.service";

export const fetchWorkSpaceDataReq = () => {
  return (dispatch?: any) => {
    HttpService.get(
      API_CONFIG.path.fetchWorkSpaces,
      {},
      { Authorization: localStorage.getItem("authToken") }
    )
      .then((data: any) => {
        dispatch(fetchWorkSpaces(data));
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        dispatch(error(err));
      });
  };
};
export const DisableWorkSpace = (status: any) => {
  return (dispatch?: any) => {
    const workspace_id = localStorage.getItem("workspace_id");
    const params = {
      workspace_id,
      disable: true,
    };
    workspaceActivateReq(params)
      .then(() => {
        dispatch(workspaceStatus(status));
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };
};
export const unableWorkSpace = (status: any) => {
  return (dispatch?: any) => {
    const workspace_id = localStorage.getItem("workspace_id");
    const params = {
      workspace_id,
      activate: true,
    };
    workspaceActivateReq(params)
      .then(() => {
        dispatch(workspaceStatus(status));
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };
};
export const DeleteworkspaceReq = (data: any) => {
  return (dispatch?: any) => {
    const workspace_id = localStorage.getItem("workspace_id");
    const params = {
      workspace_id,
    };
    deleteWorkspaceReq(params)
      .then((response: any) => {
        const afterDeleteWorkspaces = data.filter(
          (wrkspc: any) => workspace_id !== wrkspc.id
        );
        localStorage.setItem("workspace_id", afterDeleteWorkspaces[0].id);
        dispatch(workspaceStatus(afterDeleteWorkspaces));
       // window.location.reload();
      })
      .catch((error) => {
        console.log("error :", error);
      });
  };
};
