import React from 'react';
import { Button } from '@mantine/core';
import LOC from '../../assets/images/new-loc.png';

interface LocationButtonProps {
  onClick?: () => void;
  borderRadius?:string;
  width?:string;
}

export const LocationButton: React.FC<LocationButtonProps> = ({ onClick,borderRadius,width}) => {
  return (
    <Button
      variant="filled"
      onClick={onClick}
      style={{backgroundColor:'#75B121', height:'30px',width: width || '150px',borderRadius: borderRadius || '0px'}}
    >
      Location
    </Button>
  );
};
export const LocationButtonAttendence: React.FC<LocationButtonProps> = ({ onClick,borderRadius,width}) => {
  return (
    <Button
      variant="filled"
      onClick={onClick}
      style={{backgroundColor:'#75B121', height:'30px',width: width || '150px',borderRadius: borderRadius || '0px',marginTop:'8px'}}
    >
            <img src={LOC} style={{ marginRight: '8px', height: '17px', width: '15px' }} />
      Location
    </Button>
  );
};




 