import { API_CONFIG } from "../../../constant/constant";
import { GlobalObjectInterface } from "../../../shared/interface";
import HttpService from "../../../shared/services/http.service";

export const fetchAttendanceLog = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.fetchAttendanceLog, params)
      .then(({ attendance_log }: GlobalObjectInterface) => {
        resolve(attendance_log);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const fetchAttendance = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.fetchAttendance, params)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
