import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { EMAIL_REGEX } from "../../../constant/constant";
import toast, { Toaster } from "react-hot-toast";
import PropTypes from "prop-types";

const EmailTagInput = ({
  onTagsChange,
  clearOnSuccess,
  resetApiCallSuccess,
}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (clearOnSuccess) {
      setTags([]);
      resetApiCallSuccess();
    }
  }, [clearOnSuccess, resetApiCallSuccess]);

  const handleTagChange = (tags) => {
    if (tags.some((tag) => !EMAIL_REGEX.test(tag))) {
      toast.error("Invalid email format!");
    } else {
      setTags(tags);
      onTagsChange(tags);
    }
  };

  return (
    <div style={{ margin: "30px 0px 30px 0px" }}>
      <Text>Invite via email</Text>
      <TagsInput
        value={tags}
        onChange={handleTagChange}
        inputProps={{
          placeholder: "Enter email and press Enter",
          style: {
            height: "auto",
            width: "100%",
            fontSize: "14px",
            padding: "8px",
          },
        }}
        onlyUnique
        addOnBlur
        addOnPaste
      />

      <Toaster />
    </div>
  );
};

EmailTagInput.propTypes = {
  onTagsChange: PropTypes.func.isRequired,
  clearOnSuccess: PropTypes.bool.isRequired,
  resetApiCallSuccess: PropTypes.func.isRequired,
};

export default EmailTagInput;
