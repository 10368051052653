/* eslint-disable prefer-const */
import {
  Box,
  Button,
  Card,
  Center,
  Grid,
  Loader,
  Pagination,
  Select,
  Table,
  Tabs,
  Text,
} from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { PlayerPause, PlayerPlay } from "tabler-icons-react";
import { ChannelHistoryInterface } from "../../../shared/interface";
import { useDateFormat } from "../hooks/hooks";
import {
  deleteChannelHistory,
  fetchChannelHistory,
} from "../service/historyService";
import ChannelHistoryList from "./roomHistoryList";
import { fetchChannels } from "../../rooms/services/services";

function roomHistory() {
  const [allMessages, setAllMessages] = useState<ChannelHistoryInterface[]>([]);
  const [isPause, setIsPause] = useState(true);
  const [prevPlay, setPrevPlay] = useState(undefined as unknown as number);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [audioInd, setAudioInd] = useState(0);
  const historyPerPage = 50;
  const lastHistoryIndex = currentPage * historyPerPage;
  const firstHistoryIndex = lastHistoryIndex - historyPerPage;
  const currentHistory = allMessages.slice(firstHistoryIndex, lastHistoryIndex);
  const date = new Date();

  const [channelOption, setChannelOption] = useState([]);
  const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;
  const workspace_id = localStorage.getItem("workspace_id");

  const fetchRooms = () => {
    return new Promise((resolve, reject) => {
    setIsLoading(true);
    console.log(workspace_id);
    if (workspace_id) {
      const params = {
        page: 1,
        workspace_id,
      };
      fetchChannels(params)
        .then((channels: any) => {
          setIsLoading(false);
          let channel_ids=[]
          console.log(channels);
          channels.forEach(element => {
            channel_ids.push({label:element.group_name,value:element.channel_id})
          });
          resolve(channel_ids);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Error : ", error);
          resolve([]);
        });
    }
  });
};

  const confirmDelete = (id: any, index: number) => {
    return openConfirmModal({
      title: "Delete your Messages",

      children: (
        <Text size="sm">
          Are you sure you want to delete All selected messages?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "cancel" },
      confirmProps: { color: "red" },

      onConfirm: () => {
        handleDelete(id, index);
      },
    });
  };

  const handleDelete = (id: any, index: number) => {
    const params = {
      message_id: id,
    };
    deleteChannelHistory(params)
      .then((res: any) => {
        const updatedchannels = allMessages.filter(
          (channel) => channel.id !== id
        );

        setAllMessages(updatedchannels);
      })
      .catch((err: any) => {
        console.log("error:" + err);
      });
  };

  useEffect(getHistoryDetails, []);

  function getHistoryDetails(channelVal = "", from = "", to = "") {
    setIsLoading(true);
    fetchRooms().then((res: any) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].label === undefined) {
          res[i].label = "Direct";
        }
      }
      setChannelOption(res);
    
   // const workspace_id = localStorage.getItem("workspace_id") || "";
    if (workspace_id) {
      const params: {
        channel_id: string;
        page: number;
        workspace_id: any;
        from: any;
        to: any;
      } = {
        page: 1,
        workspace_id,
        channel_id: "",
        from: "",
        to: "",
      };

      if (channelVal) {
        params.channel_id = channelVal;
        (params.from = from), (params.to = to);
      }
      if (from || to) {
        params.channel_id = channelVal;
        (params.from = from), (params.to = to);
      }
      fetchChannelHistory(params)
        .then((messages: any) => {
          console.log(messages);
          setAllMessages(messages);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Error : ", error);
        });
    }
  });
  }

  function handlePlay(ind: number) {
    audioRef.current.autoplay = true;
    setAudioInd(ind * currentPage);
    setPrevPlay(ind);
    if (isPause) {
      audioRef.current.play();
      allMessages[ind].isAudioActive = true;
      setPrevPlay(ind);
      setIsPause(false);
    } else if (!isPause && ind === prevPlay) {
      audioRef.current.pause();
      setIsPause(true);
      allMessages[prevPlay].isAudioActive = false;
    } else {
      audioRef.current.currentTime;
      audioRef.current.pause();
      setIsPause(false);
      setPrevPlay(ind);
      allMessages[prevPlay].isAudioActive = false;
      allMessages[ind].isAudioActive = true;
    }
  }

  function handleDateFilter(e: any) {
    const channel_id = localStorage.getItem("current_channel") || "";

    const fromDate = useDateFormat(e[0]);
    const todate = useDateFormat(e[1]);

    if (e[0] && e[1]) {
      getHistoryDetails(channel_id, fromDate, todate);
    }
  }

  return (
    <>
      {!isLoading ? (
        currentHistory.length !== 0 ? (
          <audio
            src={allMessages[audioInd].media_link}
            itemType="audio/mp4a"
            ref={audioRef}
          />
        ) : null
      ) : null}
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Box>
          <Tabs color="lime.9" value={"activeTab"}>
            <Grid my={"2px"} gutter={"xs"}>
              <Grid.Col xs={3} sm={3} md={2} lg={2.5}>
                <DateRangePicker
                  placeholder="Date"
                  rightSection={<i className="bx bx-chevron-down" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  size={"sm"}
                  onChange={(e: any) => {
                    handleDateFilter(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={3} sm={3} md={2} lg={1.5}>
                <Select
                  size="sm"
                  onChange={(channelVal: any) => {
                    getHistoryDetails(channelVal);
                    localStorage.setItem(`current_channel`, channelVal);
                  }}
                  placeholder="Channels"
                  searchable
                  rightSection={<i className="bx bx-chevron-down" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={isLoading ? [] : channelOption}
                />
              </Grid.Col>
            </Grid>
            <Table>
              <thead>
                <tr>
                  <th className="hide-in-mobile">Date & Time</th>
                  <th style={{ width: "300px" }} className="hide-in-mobile">
                    Description
                  </th>
                  <th className="hide-in-mobile">From</th>
                  <th className="hide-in-mobile">To</th>
                  <th style={{ width: "0" }} className="hide-in-mobile"></th>
                  <th
                    style={{ textAlign: "center", width: "0" }}
                    className="hide-in-mobile"
                  >
                    Audio
                  </th>
                  <th
                    style={{ textAlign: "center", width: "0" }}
                    className="hide-in-mobile"
                  >
                    Download
                  </th>
                  <th className="hide-in-mobile">Attachment</th>
                  <th className="hide-in-mobile"></th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                        <Loader variant="bars" />
                      </Center>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {currentHistory.map(
                    (historyDetail: ChannelHistoryInterface, ind) => {
                      return (
                        <ChannelHistoryList
                          description={historyDetail.description}
                          key={ind}
                          messageatlast={historyDetail.sent_at}
                          from={historyDetail.sender_name}
                          callDur={historyDetail.duration}
                          medialink={historyDetail.media_link}
                          to={historyDetail.channel_name}
                          handleDelete={() =>
                            confirmDelete(historyDetail.id, ind)
                          }
                          attachment={historyDetail.attachments}
                        >
                          <div style={{ float: "right" }}>
                            {historyDetail.isAudioActive ? (
                              <PlayerPause
                                onClick={() => handlePlay(ind)}
                                style={{ fill: "black" }}
                              />
                            ) : (
                              <PlayerPlay
                                onClick={() => handlePlay(ind)}
                                style={{ fill: "black" }}
                              />
                            )}
                          </div>
                        </ChannelHistoryList>
                      );
                    }
                  )}
                </tbody>
              )}
            </Table>
          </Tabs>
          <Pagination
            total={Math.ceil(allMessages.length / historyPerPage)}
            styles={(theme) => ({
              item: {
                "&[data-active]": {
                  backgroundImage: theme.fn.gradient({
                    from: "#557b32",
                    to: "#88b664",
                  }),
                },
              },
            })}
            withEdges
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </Box>
      </Card>
    </>
  );
}

export default roomHistory;
