import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useManageParameters = () => {
  const location = useLocation();

  const getWorkspace_id = useMemo(() => {
    const workspaceIdFromStore = localStorage.getItem("workspace_id");
    const workspaceIdFromSearchParams = new URLSearchParams(
      location.search
    ).get("w");
    return workspaceIdFromSearchParams || workspaceIdFromStore || "";
  }, [location.search]);

  const getMParam = useMemo(() => {
    const mParamFromStore = localStorage.getItem("mParam");
    const mParamFromParams = new URLSearchParams(location.search).get("m");
    return mParamFromParams || mParamFromStore || "";
  }, [location.search]);

  const getAuthToken = useMemo(() => {
    const authTokenFromStore = localStorage.getItem("authToken");
    const authTokenFromSearchParams = new URLSearchParams(location.search).get(
      "s_t"
    );
    return authTokenFromSearchParams || authTokenFromStore || "";
  }, [location.search]);

  return {
    workspace_id: getWorkspace_id,
    authToken: getAuthToken,
    mParamFlag: getMParam,
  };
};

export default useManageParameters;
