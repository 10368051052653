import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Image,
  Text,
  Avatar,
  Grid,
  Center,
  Loader,
} from "@mantine/core";
import EMPTYWORKSPACE from "../../../assets/images/emptyWorkspace.png";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import { Toaster } from "react-hot-toast";
import httpService from "../../../shared/services/http.service";
import { useNavigate } from "react-router-dom";

const WorkspaceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [workspaces, setWorkspaces] = useState([]);

  const auth_token = location.state?.auth_token;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        setLoading(true);
        const response = await httpService.get(
          "dashboard/workspaces",
          { page: 1 },
          { Authorization: auth_token }
        );
        setWorkspaces(response?.workspaces);
      } catch (error) {
        console.error("Error fetching workspaces:", error);
      } finally {
        setLoading(false);
      }
    };

    if (auth_token) {
      fetchWorkspaces();
    }
  }, [auth_token]);

  const handleSelectWorkspace = async (workspaceId) => {
    localStorage.setItem("workspace_id", workspaceId);
    try {
      setLoading(true);
      if (workspaceId && auth_token) {
        httpService
          .get(
            "workspace_user",
            { page: 1, workspace_id: workspaceId },
            { Authorization: auth_token }
          )
          .then((response) => {
            navigate(`dashboard/users`);
          })
          .catch((error) => {
            console.error("Error selecting workspace:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Error outside fetching user list:", error);
    }
  };

  return (
    <Box>
      <Card
        shadow="sm"
        p="lg"
        my={"xs"}
        radius="md"
        withBorder
        style={{ textAlign: "left" }}
      >
        <div className="flex align-center">
          <img src={Logo} height={60} style={{ marginTop: "2px" }} />
          <h3>Talker Work</h3>
        </div>
      </Card>
      <Card
        shadow="sm"
        p="lg"
        my={"xs"}
        radius="md"
        withBorder
        style={{ textAlign: "left" }}
      >
        <Grid style={{ height: "100%" }} mt="0px" align={"center"}>
          <Center
            style={{
              width: "100%",
              height: "100%",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <Toaster toastOptions={{ duration: 4000 }} />
            <h2 className="hide-in-mobile">Welcome To Talker.Network</h2>
            <h2 className="show-in-mobile-only">Welcome To</h2>
            <h2 className="show-in-mobile-only">Talker.Network</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text size={"xl"} fw={400} color="#667085">
                Choose Your Workspace
              </Text>
            </div>
            {!workspaces || workspaces.length === 0 ? (
              <Box className="empty-workspace">
                {loading ? (
                  <Loader variant="bars" />
                ) : (
                  <>
                    <Image
                      src={EMPTYWORKSPACE}
                      width={100}
                      height={100}
                      mt={100}
                    />
                    <Text mt={10}>
                      You don&apos;t have administrative rights to any
                      workspace.
                    </Text>
                    <Text>
                      Create a workspace in the Talker mobile application
                    </Text>
                  </>
                )}
              </Box>
            ) : (
              <>
                {workspaces.map((workspace) => (
                  <Card
                    key={workspace.id}
                    shadow="sm"
                    p="lg"
                    my={"xs"}
                    radius="md"
                    withBorder
                    className="workspace-cards"
                  >
                    <Box className="mobile-no-box">
                      <Avatar
                        src={workspace?.icon}
                        mr={12}
                        className="workspace-box"
                      />
                      <Text>{workspace?.name}</Text>
                    </Box>
                    <Box>
                      <Button
                        className="workspace-button"
                        onClick={() => {
                          handleSelectWorkspace(workspace.id);
                          console.log(
                            "Clicked on workspace with ID:",
                            workspace.id
                          );
                        }}
                      >
                        Select Workspace
                      </Button>
                    </Box>
                  </Card>
                ))}
              </>
            )}
          </Center>
        </Grid>
      </Card>
    </Box>
  );
};

WorkspaceList.propTypes = {
  workspaceList: PropTypes.array.isRequired,
  handleSelectWorkspace: PropTypes.func.isRequired,
};

export default WorkspaceList;
