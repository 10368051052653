import {
  ActionIcon,
  Burger,
  Header,
  MediaQuery,
  useMantineColorScheme,
  //////////////////
  MantineTheme,
  Box,
} from "@mantine/core";

import Logo from "../assets/images/logo.png";
interface Props {
  open: boolean;
  theme: MantineTheme;
  toggleSidenav: (open: boolean) => void;
}

export default function TopHeader(props: Props) {
  const { open, theme, toggleSidenav } = props;

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <Header height={70} p="md">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={open}
              onClick={() => toggleSidenav(!open)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
          <Box>
            <div className="flex justify-center align-center">
              <img src={Logo} height={60} style={{ marginTop: "2px" }} />
              <h3>Talker Admin</h3>
            </div>
          </Box>
        </div>
        <ActionIcon
          variant="gradient"
          color={dark ? "yellow" : "blue"}
          onClick={() => toggleColorScheme()}
          title={`Turn ${dark ? "Light" : "Dark"} Theme On`}
        >
          {dark ? <i className="bx bxs-sun" /> : <i className="bx bxs-moon" />}
        </ActionIcon>
      </div>
    </Header>
  );
}
