import { useEffect, useState, useMemo, lazy } from "react";
import { AppShell, Box, Button, Card, useMantineTheme } from "@mantine/core";
import Sidenav from "./sidenav";
import TopHeader from "./topHeader";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Users } from "tabler-icons-react";
import NAV_OPTIONS from "../constant/nav";
import Profile from "../features/profile/container/profile";

import Rooms from "../features/rooms/container/rooms";
import { withRouter } from "./withRouter";

interface Props {
  children?: React.ReactNode;
}

interface MemoProps {
  open: boolean;
  setOpened: (status: boolean) => void;
}

const MemorisedSideNav = (props: MemoProps) =>
  useMemo(
    () => <Sidenav open={props.open} setOpened={props.setOpened} />,
    [props.open]
  );
const UsersDetail = withRouter(
  lazy(() => import("../features/users/container/userDetail"))
);
const RoomDetail = withRouter(
  lazy(() => import("../features/rooms/container/roomDetail"))
);
const Layout = (props: Props) => {
  const { children } = props;
  const [pageHeader, setPageHeader] = useState("");
  const [isDetailPage, setIsDetailPage] = useState(false);
  const [currentWorkspace, setCurrentWorkSpace] = useState<any>({});
  const [isLoading, setloading] = useState(true);
  const routes = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const allLevel = routes.pathname.split("/");
    const level1 =
      allLevel.length === 3
        ? allLevel[2].charAt(0).toUpperCase() + allLevel[2].slice(1)
        : allLevel[3].charAt(0).toUpperCase() + allLevel[3].slice(1);
    const level2 = `${level1} Detail`;
    setIsDetailPage(allLevel.length === 5);
    const finalLevel = allLevel.length === 5 ? level2 : level1;
    setPageHeader(level1 === "" ? "Home" : finalLevel);
  }, [routes.pathname]);

  const allWorkSpaces =
    useSelector((state: any) => state.userWorkSpaceReducer.workspaces) || [];
  const httpError = useSelector(
    (state: any) => state.userWorkSpaceReducer.error
  );

  if (httpError === 403) {
    localStorage.clear();
    navigate("/");
  }
  useEffect(() => {
    const workspace_id = localStorage.getItem("workspace_id");
    if (
      (allWorkSpaces.length > 0 && !workspace_id) ||
      workspace_id === "undefined"
    ) {
      localStorage.setItem(
        "workspace_id",
        JSON.parse(localStorage.getItem("selected_workspace") as any).id
      );
    }

    if (allWorkSpaces.length > 0) {
      const updateWorkspace = allWorkSpaces.find(
        (workspace: any) =>
          workspace.id === localStorage.getItem("workspace_id")
      );
      setCurrentWorkSpace(updateWorkspace);
      setloading(false);
    }
  }, [allWorkSpaces]);

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<MemorisedSideNav open={opened} setOpened={setOpened} />}
      header={
        <TopHeader open={opened} toggleSidenav={setOpened} theme={theme} />
      }
    >
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        mb={"sm"}
        withBorder
        style={{ textAlign: "left" }}
      >
        <Box className="flex justify-space-between align-center">
          <div>
            <h3 style={{ margin: "0" }}>
              {pageHeader}{" "}
              <span
                style={{ margin: "0", marginTop: "5px", fontWeight: "400" }}
              >
                {!isLoading
                  ? pageHeader === "Location" && !currentWorkspace.active
                    ? "(resume workspace in settings for use this feature)"
                    : ""
                  : ""}
              </span>
            </h3>
          </div>
          {isDetailPage && (
            <Button
              variant="gradient"
              size="xs"
              onClick={() => navigate(-1)}
              leftIcon={<i className="bx bxs-left-arrow-alt bx-xs" />}
            >
              Back
            </Button>
          )}
        </Box>
      </Card>
      {children}
      <Outlet />
    </AppShell>
  );
};

export default Layout;
