import { useState, useEffect } from "react";
import { Navbar, Button, Select, Divider } from "@mantine/core";
import { Route, useLocation, useNavigate } from "react-router-dom";
import NAV_OPTIONS from "../constant/nav";
import { GlobalObjectInterface } from "../shared/interface";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { action } from "./redux/reducers";
import { actionOfLoc } from "../features/roomLocation/redux/reducers";
import LogoutPolicyButtons from "./logout";

interface Props {
  open: boolean;
  setOpened: (status: boolean) => void;
}

export default function Sidenav(props: Props) {
  const mParam = localStorage.getItem("mParam");
  const routes = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { open, setOpened } = props;
  const [isWorkSpacesLoading, setIsWorkSpacesLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [isOpenedWithLink, setIsOpenedWithLink] = useState(mParam !== null && mParam !== undefined);

  // useEffect(() => {
  //   setIsOpenedWithLink(queryParams.get('m') === 'i_a');
  // }, [queryParams]);

  const allWorkSpaces =
    useSelector((state: any) => {
      return state.userWorkSpaceReducer.workspaces;
    }) || [];

  const [workSpaceOptions, setWorkSpaceOptions] = useState<
    { label: string; value: string; active: boolean }[]
  >([]);
  const [workSpace, setWorkSpace] = useState(
    localStorage.getItem("workspace_id") || ""
  );
  const dispatch = useDispatch();
  const { fetchWorkSpaceDataReq } = bindActionCreators(action, dispatch);
  const { fetchLocationData } = bindActionCreators(actionOfLoc, dispatch);

  const convertAllWorkspaceInSelectdataFormat = () => {
    setIsWorkSpacesLoading(true);
    setIsWorkSpacesLoading(false);

    const workSpaceOptions = allWorkSpaces.map(
      ({ id, name, active }: GlobalObjectInterface) => ({
        label: name,
        value: id,
        active: JSON.stringify(active),
      })
    );
    setWorkSpaceOptions(workSpaceOptions);
    localStorage.setItem("workSpaceOptions", JSON.stringify(workSpaceOptions));
  };

  useEffect(() => {
    if (allWorkSpaces.length > 0 && workSpace === "") {
      localStorage.setItem(
        "selected_workspace",
        JSON.stringify(
          allWorkSpaces.find(
            (ws: GlobalObjectInterface) => ws.id === workSpace
          ) || allWorkSpaces[0]
        )
      );
    }
    if (workSpace !== "") {
      localStorage.setItem(
        "selected_workspace",
        JSON.stringify(
          allWorkSpaces.find(
            (ws: GlobalObjectInterface) => ws.id === workSpace
          ) || allWorkSpaces[0]
        )
      );
    }
    convertAllWorkspaceInSelectdataFormat();
  }, [allWorkSpaces, workSpace]);

  useEffect(() => {
    fetchWorkSpaceDataReq();

    convertAllWorkspaceInSelectdataFormat();
  }, []);

  useEffect(() => {
    const workSpaceOption = localStorage.getItem("workSpaceOptions");
    const localStorageSelectedWorkSpace =
      localStorage.getItem("selected_workspace");
    const selectedWorkspace =
      localStorageSelectedWorkSpace !== "undefined" &&
      localStorageSelectedWorkSpace !== null
        ? JSON.parse(localStorageSelectedWorkSpace)
        : null;
    if (!workSpace && allWorkSpaces.length > 0) {
      setWorkSpace(allWorkSpaces[0].id);
      localStorage.setItem("workspace_id", allWorkSpaces[0].id);
    }
    if (!selectedWorkspace) {
      localStorage.setItem(
        "selected_workspace",
        JSON.stringify(allWorkSpaces[0])
      );
    }
    if (selectedWorkspace !== null) {
      fetchLocationData(selectedWorkspace.active);
    }
  }, [allWorkSpaces]);

  const getActiveNav = (currentRoute: string) => {
    return location.pathname.split("/")[3] === currentRoute
      ? "gradient"
      : `/${location.pathname.split("/")[1]}` === currentRoute &&
        location.pathname.split("/").length <= 2
      ? "gradient"
      : "default";
  };

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!open}
      style={{ textAlign: "left" }}
      width={{ sm: 200, lg: 300 }}
    >
      <Select
        placeholder="Workspace"
        value={
          workSpace
            ? workSpace
            : allWorkSpaces.length !== 0
            ? allWorkSpaces[0].id
            : ""
        }
        disabled={isWorkSpacesLoading || !workSpaceOptions.length}
        onChange={(selectedOption) => {
          if (selectedOption) {
            setWorkSpace(selectedOption);
            localStorage.setItem("workspace_id", selectedOption);
            localStorage.setItem(
              "selected_workspace",
              JSON.stringify(
                allWorkSpaces.find(
                  (ws: GlobalObjectInterface) => ws.id === selectedOption
                ) || {}
              )
            );
            const url = routes.pathname.split("/");
            if (url.length === 4) {
              navigate(`${url[3]}`);
            }
            window.location.reload();
          }
        }}
        rightSection={<i className="bx bx-chevron-down" />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
        data={workSpaceOptions}
      />
      <Divider my="sm" />
      {Object.keys(NAV_OPTIONS).map((nav, _ind) => {
        const { path, icon, label } = NAV_OPTIONS[nav];
        if (nav === 'PLAN' && isOpenedWithLink) {
          return null; 
        }
        return (
          <Button
            key={nav}
            variant={getActiveNav(path)}
            size="sm"
            radius="sm"
            mb={"sm"}
            onClick={() => {
              navigate(path);
              setOpened(false);
            }}
            leftIcon={icon}
          >
            {label}
          </Button>
        );
      })}
      <LogoutPolicyButtons/>
    </Navbar>
  );
}
