import { API_CONFIG } from "../../../constant/constant";
import {
  GlobalObjectInterface,
  ChannelInterface,
} from "../../../shared/interface";
import HttpService from "../../../shared/services/http.service";

export const fetchChannelHistory = (params: any) => {
  if (params.channel_id === "") {
    delete params.channel_id;
  }
  if (params.from === "") {
    delete params.from;
  }
  if (params.to === "") {
    delete params.to;
  }
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.MessageHistory, params)
      .then(({ messages }: GlobalObjectInterface) => {
        resolve(messages);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const deleteChannelHistory = (queryParams: any) => {
  return new Promise((resolve, reject) => {
    HttpService.deleteRequest(API_CONFIG.path.MessageHistory, {}, queryParams)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
