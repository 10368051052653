import actionType from "./actionType";
import intialData from "./intialData";

function userLocationReducer(
  state = intialData,
  action: {
    payload: any;
    type: string;
  }
) {
  if (actionType.USER_LOCATION_FETCH === action.type) {
    return { ...state, users: action.payload, isRefreshing: false };
  } else if (actionType.ISREFRESHING === action.type) {
    return { ...state, isRefreshing: action.payload };
  } else {
    return state;
  }
}
export default userLocationReducer;
