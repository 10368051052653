export const getFirstUpperCaseString = (string?: string) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const debounce = (func: any, wait = 400) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};