export interface NAV_OPTION_INTERFACE {
  path: string;
  label: string;
  icon: JSX.Element;
}

interface NAV_OPTIONS {
  [nav: string]: NAV_OPTION_INTERFACE;
}

const NAV_OPTIONS: NAV_OPTIONS = {
  //DASHBOARD: {
  //  path: "/dashboard",
  //  label: "Dashboard",
  //  icon: <i className="bx bxs-grid-alt" />,
  //},
  // PROFILE: {
  //   path: "profile",
  //   label: "Profile",
  //   icon: <i className="bx bxs-user" />,
  // },
  USERS: {
    path: "users",
    label: "Users",
    icon: <i className="bx bx-group" />,
  },
  ATTENDANCE: {
    path: "attendance",
    label: "Attendance",
    icon: <i className="bx bx-user-check"/>,
  },
  CHANNELS: {
    path: "channels",
    label: "Channels",
    icon: <i className="bx bxs-dashboard" />,
  },
  HISTORY: {
    path: "history",
    label: "History",
    icon: (
      <i
        className="bx bx-history"
        style={{ fontSize: "18px", fontWeight: "bold" }}
      />
    ),
  },
  LOCATION: {
    path: "location",
    label: "Location",
    icon: (
      <i
        className="bx bx-map-pin"
        style={{ fontSize: "15px", fontWeight: "bold" }}
      />
    ),
  },
  SETTINGS: {
    path: "workplace-settings",
    label: "Workplace Settings",
    // icon: <i className="bx bxs-cog" />,
    icon: <i className="bx bx-group" />
  },
  PLAN: {
    path: "plan-and-billing",
    label: "Plan and Billing",
    icon: (
      <i
        className="bx bx-map-pin"
        style={{ fontSize: "15px", fontWeight: "bold" }}
      />
    ),
  },
};

export default NAV_OPTIONS;
