import { Box, Card } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo.png";
import toast from "react-hot-toast";
import { Loader, Center } from "@mantine/core";
import { API_CONFIG } from "../../../constant/constant";

const PaddlePayment = () => {
  const Paddle = window.Paddle;

  const [isValidUrl, setValidUrl] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPaymentPortalClosed,setPaymentPortalClosed]=useState(false);
  const[isPaymentSuccessful,setPaymentSuccess]=useState(false);
  useEffect(() => {
    setLoading(true);
    const url = new URL(window.location.href);
    const requiredParams = ["w_t", "p_t"];
    const urlParams = new URLSearchParams(url.search);
    let allParamsPresent = true;

    for (const param of requiredParams) {
      if (!urlParams.has(param)) {
        allParamsPresent = false;
        break;
      }
    }

    if (allParamsPresent) {
      const queryParams = {
        workspace_id: urlParams.get("w_t"),
        plan_id: urlParams.get("p_t"),
        coupon_code: urlParams.get("c_t"),
      };

      const baseUrl = process.env.REACT_APP_BASE_URL || "";
      const newUrl = new URL("/superadmin/payment_link", baseUrl);
      Object.keys(queryParams).forEach((key) =>
        newUrl.searchParams.append(key, queryParams[key])
      );

        // Hit the new URL
        try {
          fetch(newUrl.href, {
            method: "GET",
            headers: {
              Authorization: API_CONFIG.globalAuth,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);

              if (data?.data?.payment_link) {
                  console.log(data?.data?.payment_link);
                Paddle.Checkout.open({
                  override: data?.data?.payment_link,
                  successCallback: () => {
                    //toast.success("Payment Successfull")
                    setPaymentSuccess(true)
                  },
                  closeCallback:()=>
                  {console.log('closed portal')
                   // toast.success("Payment session closed")
                    setPaymentPortalClosed(true)
                  }
                } as any);
              } else {
                setValidUrl(false);
              }
            });
        } catch (error) {
          setLoading(false);
          setValidUrl(false);
          console.log(error);
        }
    } else {
      setLoading(false);
      setValidUrl(false);
    }
  }, []);

  return (
    <Box>
      <Card
        shadow="sm"
        p="lg"
        my={"xs"}
        radius="md"
        withBorder
        style={{ textAlign: "left" }}
      >
        <div className="flex align-center">
          <img src={Logo} height={60} style={{ marginTop: "2px" }} />
          <h3>Talker Work</h3>
        </div>
      </Card>

      {isValidUrl ? (
        <></>
      ) : (
        <Center style={{ minHeight: "calc(100vh - 386px)" }}>
          <h2>The payment link is invalid</h2>
        </Center>
      )}
       {!isPaymentSuccessful ? (
        <></>
      ) : (
        <Center style={{ minHeight: "calc(100vh - 386px)" }}>
          <h2>The payment was successful !!</h2>
        </Center>
      )}
       {!isPaymentPortalClosed ? (
        <></>
      ) : (
        <Center style={{ minHeight: "calc(100vh - 386px)" }}>
          <h2>Payment portal was closed , Refresh the page to try paying again .</h2>
        </Center>
      )}
      <>
        {loading ? (
          <Center style={{ minHeight: "calc(100vh - 386px)" }}>
            <Loader variant="bars" />
          </Center>
        ) : (
          <></>
        )}
      </>
    </Box>
  );
};

export default PaddlePayment;
