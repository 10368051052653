/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Select,
  Text,
} from "@mantine/core";

interface Props {
  children: any;
  onRefresh?: any;
  refreshVal?: boolean;
}
function SearchBox({ children, onRefresh, refreshVal }: Props) {
  return (
    <div
      style={{
        height: "100%",
        width: "fit-content",
        maxHeight: "400px",
        position: "absolute",
      }}
    >
      <Card m={20} radius={15}>
        <Flex style={{ width: "248px" }}>
          <Container p={0} style={{ float: "left", width: "134px" }}>
            <Text fw={600}>User's Location</Text>
          </Container>
          <Container p={0} style={{ float: "right" }}>
            <Button size={"xs"} onClick={onRefresh} loading={refreshVal}>
              Refresh
            </Button>
          </Container>
        </Flex>
        <Box mt={10} ml={10} mr={10}>
          {children}
        </Box>
      </Card>
    </div>
  );
}

export default SearchBox;
