import { AnyAction, Dispatch } from "redux";
import { fetchUserLocation } from "../../service/locationService";
import { fetchLocation, refreshAction } from "./actions";
export const fetchLocationData = (isActive: boolean) => {
  return (dispatch: Dispatch<AnyAction>) => {
    const workspace_id = localStorage.getItem("workspace_id");
    const params = {
      page: 0,
      workspace_id,
    };
    if (isActive) {
      fetchUserLocation(params).then((data) => {
        dispatch(fetchLocation(data));
      });
      setInterval(() => {
        fetchUserLocation(params).then((data) => {
          dispatch(fetchLocation(data));
        });
      }, 60000);
    }
  };
};

export const handleRefreshing = (refresh) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(refreshAction(refresh));
  };
};
