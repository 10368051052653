import { Box, Button, Group, TextInput, Select, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { GlobalObjectInterface } from "../../../shared/interface";

interface Props {
  actionLoading: boolean;
  closeModal: () => void;
  onSubmit: (values: { name: string }) => void;
}

const CreateChannel = (props: Props) => {
  const { actionLoading, closeModal, onSubmit } = props;

  const form = useForm({
    initialValues: {
      name: "",
    },

    validate: {
      name: (value) => (value ? null : "Please select name"),
    },
  });

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TextInput
          autoFocus
          disabled={actionLoading}
          my="xs"
          label={<b>Name</b>}
          placeholder="Name"
          {...form.getInputProps("name")}
        />
        <Group position="right" mt="md">
          <Button type="reset" variant="default" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" variant="gradient" loading={actionLoading}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  );
};
export default CreateChannel;
