import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpService from "../shared/services/http.service";
import { CurrencyCent } from "tabler-icons-react";

export type TSaveParam = {
  originalName: string;
  givenName: string;
};

 //async function 
 const
 useSaveParams  =() =>{
  const [somethingWrong, setSomethingWrong] = useState(false);
  const location = useLocation();
  console.log('checking params');
  const paramsToSave: TSaveParam[] = [
    { originalName: "s_t", givenName: "authToken" },
    { originalName: "m", givenName: "mParam" },
    { originalName: "w", givenName: "workspace_id" },
  ];

  const getCurrentUser = async (workspace_id: string, authToken: string) => {
    try {
      const data = await httpService.post(
        "dashboard/session/start",
        {},
        { workspace_id: workspace_id },
        { Authorization: authToken }
      );
      localStorage.setItem("current_user", JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(
    
   // async
     () => {
    const urlSearchParams = new URLSearchParams(location.search);
    //console.log(urlSearchParams.get('w'))
    let storedToken = localStorage.getItem("authToken") || "";
    let currentUser =
      JSON.parse(localStorage.getItem("current_user")) || null;
   // console.log("current user",currentUser)
    if (storedToken && urlSearchParams.get("s_t")) {
      
     
      if ( urlSearchParams.get("s_t") !== storedToken )
      { 
       //  console.log('stored st token is mistmatch to one in query param')
       //  console.log('clearing local storage')
        localStorage.clear()
        storedToken=null;
        currentUser=null;

        //return setSomethingWrong(true);
      }
     
    
    }
    
    
     // console.log('storing param values in local storage in no stored token')
      paramsToSave.forEach((param) => {
        
        const paramValue = urlSearchParams.get(param.originalName);
        if (paramValue) {
          localStorage.setItem(param.givenName, paramValue);
          if ( storedToken && storedToken===urlSearchParams.get("s_t") && param.originalName==="w" && currentUser)
          {
           // console.log('different workspaceId for same storedToken')
            currentUser['workspace_id']=paramValue;
            localStorage.setItem("current_user",JSON.stringify(currentUser))
          }
        }

      });
    

    // If currentUser is null or undefined
    if (!currentUser) {
      let workspaceId = "";
      let authToken = "";

      // Extract the parameter values from urlSearchParams
      paramsToSave.forEach((param) => {
        const paramValue = urlSearchParams.get(param.originalName);
        if (paramValue) {
          if (param.originalName === "w") {
            workspaceId = paramValue;
          } else if (param.originalName === "s_t") {
            authToken = paramValue;
          }
        }
      });

      // Call getCurrentUser with the extracted parameters
      //await
       getCurrentUser(workspaceId, authToken);
    
  }
  }, [location.search]);

  return null;
}

export default useSaveParams;
