import { OverlayView } from "@react-google-maps/api";
import React from "react";
interface Props {
  Center: {
    lat: any;
    lng: any;
  };
  isLive: boolean;
  imgPath: string;
}
export default function LocationMarker({ Center, isLive, imgPath }: Props) {
  return (
    <OverlayView position={Center} mapPaneName={"markerLayer"}>
      <div
        style={{
          marginLeft: "-46px",
          marginTop: "-72px",
          position: "relative",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="70pt"
          height="70pt"
          version="1.1"
          viewBox="0 0 700 700"
          fill={isLive ? "red" : "green"}
        >
          <defs>
            <symbol id="r" overflow="visible">
              <path d="m28.422-1.7031c-1.4688 0.76172-3 1.3359-4.5938 1.7188-1.5859 0.38281-3.2344 0.57812-4.9531 0.57812-5.1562 0-9.2422-1.4375-12.25-4.3125-3.0117-2.875-4.5156-6.7812-4.5156-11.719s1.5039-8.8438 4.5156-11.719c3.0078-2.8828 7.0938-4.3281 12.25-4.3281 1.7188 0 3.3672 0.19531 4.9531 0.57812 1.5938 0.38672 3.125 0.96094 4.5938 1.7188v6.4062c-1.4805-1.0078-2.9375-1.75-4.375-2.2188s-2.9492-0.70312-4.5312-0.70312c-2.8438 0-5.0859 0.91406-6.7188 2.7344-1.625 1.8242-2.4375 4.3359-2.4375 7.5312 0 3.1992 0.8125 5.7109 2.4375 7.5312 1.6328 1.8242 3.875 2.7344 6.7188 2.7344 1.582 0 3.0938-0.23438 4.5312-0.70312 1.4375-0.47656 2.8945-1.2188 4.375-2.2188z" />
            </symbol>
            <symbol id="f" overflow="visible">
              <path d="m20.797-16.875c-0.64844-0.3125-1.293-0.53516-1.9375-0.67188-0.64844-0.14453-1.293-0.21875-1.9375-0.21875-1.9062 0-3.375 0.60938-4.4062 1.8281-1.0234 1.2188-1.5312 2.9688-1.5312 5.25v10.688h-7.4219v-23.203h7.4219v3.8125c0.94531-1.5195 2.0352-2.6289 3.2656-3.3281 1.2383-0.69531 2.7227-1.0469 4.4531-1.0469 0.25 0 0.51953 0.011719 0.8125 0.03125 0.28906 0.023437 0.71094 0.070313 1.2656 0.14062z" />
            </symbol>
            <symbol id="e" overflow="visible">
              <path d="m26.719-11.656v2.1094h-17.328c0.17578 1.7422 0.80078 3.0469 1.875 3.9219 1.082 0.86719 2.5859 1.2969 4.5156 1.2969 1.5625 0 3.1602-0.22656 4.7969-0.6875 1.6328-0.46875 3.3164-1.1719 5.0469-2.1094v5.7188c-1.75 0.65625-3.5078 1.1523-5.2656 1.4844-1.75 0.34375-3.5078 0.51562-5.2656 0.51562-4.1992 0-7.4609-1.0625-9.7812-3.1875-2.3242-2.1328-3.4844-5.1289-3.4844-8.9844 0-3.7812 1.1406-6.7539 3.4219-8.9219 2.2812-2.1758 5.4258-3.2656 9.4375-3.2656 3.6445 0 6.5625 1.1016 8.75 3.2969 2.1875 2.1992 3.2812 5.1367 3.2812 8.8125zm-7.625-2.4688c0-1.4062-0.41406-2.5391-1.2344-3.4062-0.8125-0.86328-1.8867-1.2969-3.2188-1.2969-1.4375 0-2.6055 0.40625-3.5 1.2188-0.89844 0.80469-1.4531 1.9648-1.6719 3.4844z" />
            </symbol>
            <symbol id="i" overflow="visible">
              <path d="m13.969-10.438c-1.5547 0-2.7188 0.26562-3.5 0.79688-0.78125 0.52344-1.1719 1.293-1.1719 2.3125 0 0.9375 0.3125 1.6719 0.9375 2.2031 0.63281 0.53125 1.5078 0.79688 2.625 0.79688 1.3945 0 2.5664-0.5 3.5156-1.5 0.95703-1 1.4375-2.2539 1.4375-3.7656v-0.84375zm11.328-2.7969v13.234h-7.4844v-3.4375c-0.99219 1.4062-2.1094 2.4336-3.3594 3.0781-1.2422 0.63281-2.75 0.95312-4.5312 0.95312-2.4062 0-4.3594-0.69531-5.8594-2.0938-1.4922-1.4062-2.2344-3.2227-2.2344-5.4531 0-2.7266 0.92969-4.7266 2.7969-6 1.875-1.2695 4.8125-1.9062 8.8125-1.9062h4.375v-0.57812c0-1.1641-0.46484-2.0195-1.3906-2.5625-0.92969-0.55078-2.3711-0.82812-4.3281-0.82812-1.5859 0-3.0625 0.16406-4.4375 0.48438-1.3672 0.3125-2.6328 0.78906-3.7969 1.4219v-5.6562c1.582-0.38281 3.1758-0.67578 4.7812-0.875 1.6016-0.20703 3.2031-0.3125 4.7969-0.3125 4.1875 0 7.207 0.82812 9.0625 2.4844 1.8633 1.6484 2.7969 4.3281 2.7969 8.0469z" />
            </symbol>
            <symbol id="a" overflow="visible">
              <path d="m11.656-29.781v6.5781h7.6562v5.3125h-7.6562v9.8281c0 1.0859 0.21094 1.8203 0.64062 2.2031 0.4375 0.375 1.2891 0.5625 2.5625 0.5625h3.7969v5.2969h-6.3594c-2.9297 0-5-0.60938-6.2188-1.8281-1.2188-1.2266-1.8281-3.3047-1.8281-6.2344v-9.8281h-3.6875v-5.3125h3.6875v-6.5781z" />
            </symbol>
            <symbol id="h" overflow="visible">
              <path d="m19.344-19.797v-12.438h7.4531v32.234h-7.4531v-3.3594c-1.0234 1.3672-2.1484 2.3672-3.375 3-1.2305 0.63281-2.6523 0.95312-4.2656 0.95312-2.8555 0-5.2031-1.1328-7.0469-3.4062-1.8359-2.2695-2.75-5.1914-2.75-8.7656 0-3.5703 0.91406-6.4922 2.75-8.7656 1.8438-2.2812 4.1914-3.4219 7.0469-3.4219 1.6016 0 3.0195 0.32422 4.25 0.96875 1.2383 0.64844 2.3672 1.6484 3.3906 3zm-4.8906 15.016c1.5938 0 2.8047-0.57812 3.6406-1.7344 0.83203-1.1641 1.25-2.8516 1.25-5.0625 0-2.207-0.41797-3.8906-1.25-5.0469-0.83594-1.1641-2.0469-1.75-3.6406-1.75-1.5742 0-2.7773 0.58594-3.6094 1.75-0.83594 1.1562-1.25 2.8398-1.25 5.0469 0 2.2109 0.41406 3.8984 1.25 5.0625 0.83203 1.1562 2.0352 1.7344 3.6094 1.7344z" />
            </symbol>
            <symbol id="d" overflow="visible">
              <path d="m15.906-4.7812c1.5938 0 2.8047-0.57812 3.6406-1.7344 0.83203-1.1641 1.25-2.8516 1.25-5.0625 0-2.207-0.41797-3.8906-1.25-5.0469-0.83594-1.1641-2.0469-1.75-3.6406-1.75-1.5859 0-2.8047 0.58594-3.6562 1.75-0.84375 1.168-1.2656 2.8516-1.2656 5.0469 0 2.1992 0.42188 3.8828 1.2656 5.0469 0.85156 1.168 2.0703 1.75 3.6562 1.75zm-4.9219-15.016c1.0195-1.3516 2.1484-2.3516 3.3906-3 1.2383-0.64453 2.6641-0.96875 4.2812-0.96875 2.8633 0 5.2109 1.1406 7.0469 3.4219 1.832 2.2734 2.75 5.1953 2.75 8.7656 0 3.5742-0.91797 6.4961-2.75 8.7656-1.8359 2.2734-4.1836 3.4062-7.0469 3.4062-1.6172 0-3.043-0.32031-4.2812-0.95312-1.2422-0.64453-2.3711-1.6445-3.3906-3v3.3594h-7.4219v-32.234h7.4219z" />
            </symbol>
            <symbol id="c" overflow="visible">
              <path d="m0.51562-23.203h7.4219l6.2344 15.75 5.2969-15.75h7.4219l-9.7656 25.406c-0.98047 2.5703-2.1211 4.375-3.4219 5.4062-1.3047 1.0312-3.0273 1.5469-5.1719 1.5469h-4.2812v-4.875h2.3125c1.2578 0 2.1758-0.19922 2.75-0.59375 0.57031-0.39844 1.0156-1.1172 1.3281-2.1562l0.21875-0.64062z" />
            </symbol>
            <symbol id="b" overflow="visible">
              <path d="m3.8906-30.922h13.234c3.9375 0 6.957 0.875 9.0625 2.625 2.1016 1.7422 3.1562 4.2266 3.1562 7.4531 0 3.25-1.0547 5.75-3.1562 7.5-2.1055 1.7422-5.125 2.6094-9.0625 2.6094h-5.25v10.734h-7.9844zm7.9844 5.7812v8.625h4.4062c1.5391 0 2.7344-0.375 3.5781-1.125s1.2656-1.8164 1.2656-3.2031c0-1.375-0.42188-2.4297-1.2656-3.1719-0.84375-0.75-2.0391-1.125-3.5781-1.125z" />
            </symbol>
            <symbol id="g" overflow="visible">
              <path d="m14.609-18.453c-1.6484 0-2.9023 0.59375-3.7656 1.7812-0.86719 1.1797-1.2969 2.875-1.2969 5.0938s0.42969 3.9219 1.2969 5.1094c0.86328 1.1797 2.1172 1.7656 3.7656 1.7656 1.6133 0 2.8477-0.58594 3.7031-1.7656 0.85156-1.1875 1.2812-2.8906 1.2812-5.1094s-0.42969-3.9141-1.2812-5.0938c-0.85547-1.1875-2.0898-1.7812-3.7031-1.7812zm0-5.3125c3.9883 0 7.1016 1.0781 9.3438 3.2344 2.25 2.1562 3.375 5.1406 3.375 8.9531s-1.125 6.7969-3.375 8.9531c-2.2422 2.1484-5.3555 3.2188-9.3438 3.2188-4.0117 0-7.1484-1.0703-9.4062-3.2188-2.25-2.1562-3.375-5.1406-3.375-8.9531s1.125-6.7969 3.375-8.9531c2.2578-2.1562 5.3945-3.2344 9.4062-3.2344z" />
            </symbol>
            <symbol id="q" overflow="visible">
              <path d="m25.422-29.953v6.5469c-1.6992-0.75781-3.3594-1.332-4.9844-1.7188-1.6172-0.38281-3.1406-0.57812-4.5781-0.57812-1.8984 0-3.3047 0.26562-4.2188 0.79688-0.90625 0.52344-1.3594 1.3359-1.3594 2.4375 0 0.82422 0.30469 1.4688 0.92188 1.9375 0.61328 0.46094 1.7266 0.85547 3.3438 1.1875l3.3906 0.6875c3.4375 0.6875 5.8789 1.7344 7.3281 3.1406 1.457 1.4062 2.1875 3.4141 2.1875 6.0156 0 3.4062-1.0156 5.9453-3.0469 7.6094-2.0234 1.6562-5.1094 2.4844-9.2656 2.4844-1.9609 0-3.9297-0.1875-5.9062-0.5625-1.9688-0.36328-3.9453-0.91016-5.9219-1.6406v-6.7344c1.9766 1.0547 3.8906 1.8438 5.7344 2.375s3.6172 0.79688 5.3281 0.79688c1.7383 0 3.0703-0.28906 4-0.875 0.92578-0.58203 1.3906-1.4102 1.3906-2.4844 0-0.96875-0.32031-1.7109-0.95312-2.2344-0.625-0.53125-1.875-1.0039-3.75-1.4219l-3.0938-0.67188c-3.0938-0.66406-5.3555-1.7227-6.7812-3.1719-1.4297-1.4453-2.1406-3.3984-2.1406-5.8594 0-3.082 0.99219-5.4531 2.9844-7.1094 1.9883-1.6562 4.8477-2.4844 8.5781-2.4844 1.6953 0 3.4414 0.13281 5.2344 0.39062 1.7891 0.25 3.6484 0.63281 5.5781 1.1406z" />
            </symbol>
            <symbol id="p" overflow="visible">
              <path d="m25.062-19.344c0.9375-1.4375 2.0508-2.5312 3.3438-3.2812 1.2891-0.75781 2.7109-1.1406 4.2656-1.1406 2.6562 0 4.6797 0.82422 6.0781 2.4688 1.3945 1.6484 2.0938 4.0391 2.0938 7.1719v14.125h-7.4531v-12.094c0.007813-0.17578 0.019531-0.36328 0.03125-0.5625 0.007813-0.19531 0.015625-0.47266 0.015625-0.82812 0-1.6445-0.24609-2.8359-0.73438-3.5781-0.48047-0.73828-1.2617-1.1094-2.3438-1.1094-1.4062 0-2.4961 0.58594-3.2656 1.75-0.76172 1.1562-1.1562 2.8359-1.1875 5.0312v11.391h-7.4531v-12.094c0-2.5703-0.22656-4.2266-0.67188-4.9688-0.4375-0.73828-1.2188-1.1094-2.3438-1.1094-1.4297 0-2.5273 0.58594-3.2969 1.75-0.77344 1.168-1.1562 2.8398-1.1562 5.0156v11.406h-7.4688v-23.203h7.4688v3.4062c0.90625-1.3125 1.9453-2.3008 3.125-2.9688 1.1875-0.66406 2.4883-1 3.9062-1 1.6016 0 3.0195 0.39062 4.25 1.1719 1.2266 0.77344 2.1602 1.8555 2.7969 3.25z" />
            </symbol>
            <symbol id="o" overflow="visible">
              <path d="m3.5625-32.234h7.4219v32.234h-7.4219z" />
            </symbol>
            <symbol id="n" overflow="visible">
              <path d="m21.688-22.469v5.625c-1.5859-0.65625-3.1172-1.1484-4.5938-1.4844-1.4805-0.33203-2.875-0.5-4.1875-0.5-1.4062 0-2.4531 0.17969-3.1406 0.53125-0.6875 0.35547-1.0312 0.89844-1.0312 1.625 0 0.59375 0.25781 1.0547 0.78125 1.375 0.51953 0.3125 1.4453 0.54297 2.7812 0.6875l1.3125 0.1875c3.8008 0.49219 6.3516 1.2891 7.6562 2.3906 1.3125 1.1055 1.9688 2.8398 1.9688 5.2031 0 2.4688-0.91406 4.3242-2.7344 5.5625-1.8242 1.2422-4.543 1.8594-8.1562 1.8594-1.5312 0-3.1172-0.12109-4.75-0.35938-1.6367-0.23828-3.3203-0.59766-5.0469-1.0781v-5.6406c1.4766 0.71875 2.9922 1.2617 4.5469 1.625 1.5508 0.35547 3.1289 0.53125 4.7344 0.53125 1.4453 0 2.5352-0.19531 3.2656-0.59375 0.73828-0.40625 1.1094-1.0039 1.1094-1.7969 0-0.65625-0.25781-1.1445-0.76562-1.4688-0.5-0.32031-1.5078-0.57812-3.0156-0.76562l-1.2969-0.15625c-3.3047-0.41406-5.6172-1.1797-6.9375-2.2969-1.3242-1.125-1.9844-2.8281-1.9844-5.1094 0-2.457 0.83594-4.2812 2.5156-5.4688 1.6875-1.1875 4.2695-1.7812 7.75-1.7812 1.3633 0 2.7969 0.10547 4.2969 0.3125 1.5078 0.21094 3.1484 0.53906 4.9219 0.98438z" />
            </symbol>
            <symbol id="m" overflow="visible">
              <path d="m18.828-32.234v4.875h-4.0938c-1.0547 0-1.7891 0.19531-2.2031 0.57812-0.41797 0.375-0.625 1.0312-0.625 1.9688v1.6094h6.3438v5.3125h-6.3438v17.891h-7.4062v-17.891h-3.6875v-5.3125h3.6875v-1.6094c0-2.5312 0.70312-4.3984 2.1094-5.6094 1.4062-1.207 3.5859-1.8125 6.5469-1.8125z" />
            </symbol>
            <symbol id="l" overflow="visible">
              <path d="m26.891-14.125v14.125h-7.4688v-10.766c0-2.0312-0.046875-3.4258-0.14062-4.1875-0.085938-0.75781-0.23438-1.3203-0.45312-1.6875-0.29297-0.47656-0.6875-0.85156-1.1875-1.125-0.49219-0.26953-1.0547-0.40625-1.6875-0.40625-1.5547 0-2.7734 0.60156-3.6562 1.7969-0.875 1.1992-1.3125 2.8555-1.3125 4.9688v11.406h-7.4219v-32.234h7.4219v12.438c1.1133-1.3516 2.2969-2.3516 3.5469-3 1.2578-0.64453 2.6484-0.96875 4.1719-0.96875 2.6758 0 4.707 0.82422 6.0938 2.4688 1.3945 1.6484 2.0938 4.0391 2.0938 7.1719z" />
            </symbol>
            <symbol id="k" overflow="visible">
              <path d="m3.8906-30.922h8.9062l11.25 21.203v-21.203h7.5625v30.922h-8.9062l-11.25-21.219v21.219h-7.5625z" />
            </symbol>
            <symbol id="j" overflow="visible">
              <path d="m3.5625-23.203h7.4219v22.781c0 3.1133-0.75 5.4883-2.25 7.125-1.4922 1.6328-3.6523 2.4531-6.4844 2.4531h-3.6562v-4.875h1.2812c1.4062 0 2.3672-0.32031 2.8906-0.95312 0.53125-0.63672 0.79688-1.8867 0.79688-3.75zm0-9.0312h7.4219v6.0469h-7.4219z" />
            </symbol>
          </defs>
          <g>
            <path d="m350 8.4844c-104.28 0-188.7 84.426-188.7 188.7 0 85.527 56.848 157.82 135 180.89l53.707 173.43 53.711-173.43c78.145-23.078 134.99-95.371 134.99-180.89 0-104.28-84.426-188.71-188.7-188.71zm0 318.01c-71.359 0-129.31-57.953-129.31-129.31 0-71.359 57.953-129.39 129.31-129.39 71.359 0 129.31 58.035 129.31 129.39 0 71.355-57.949 129.31-129.31 129.31z" />
          </g>
        </svg>
        <div
          style={{
            marginLeft: "-46px",
            marginTop: "-72px",
            position: "absolute",
            width: "5px",
            left: "72.29px",
            top: "78px",
          }}
        >
          <img
            src={imgPath}
            width={40}
            height={40}
            style={{ borderRadius: "100%" }}
          />
        </div>
      </div>
    </OverlayView>
  );
}
