import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  // {/* </React.StrictMode> */}
);

window.onload = function () {
  if (window.Paddle) {
    if (process.env.REACT_APP_ENVIRONMENT==='development')
       { window.Paddle.Environment.set("sandbox");
        console.log("sandbox setup---------")
       }

    window.Paddle.Setup({ vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || '0', 10) });
  }
 
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
