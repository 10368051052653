import { Modal } from "@mantine/core";
import { ReactNode } from "react";

interface ModalProps {
  open: boolean;
  title?: string | ReactNode;
  closeModal: () => void;
  children: ReactNode;

  className?: string;
  centered?: boolean;
  withCloseButton?: boolean;
}

const CustomModal = (props: ModalProps) => {
  const {
    open,
    title,
    withCloseButton,
    centered,
    children,
    className,
    closeModal,
  } = props;
  return (
    <Modal
      opened={open}
      withCloseButton={withCloseButton || true}
      className={className}
      transition={`slide-${open ? "down" : "up"}`}
      onClose={closeModal}
      title={typeof title === "string" ? <h3>{title || "Title"}</h3> : title}
      centered={centered || false}
    >
      {/* Modal content */}
      {children}
    </Modal>
  );
};

export default CustomModal;
