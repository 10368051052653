import HttpService from "../../../shared/services/http.service";
import { GlobalObjectInterface } from "../../../shared/interface";
import { API_CONFIG } from "../../../constant/constant";

export const fetchUserLocation = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.userLocation, params)
      .then(({ workspace_user_locations }: GlobalObjectInterface) => {
        resolve(workspace_user_locations);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
