import actionType from "./actionType";

interface FetchDataAction {
  type: typeof actionType.USER_LOCATION_FETCH;
  payload: any;
}
interface refreshAction {
  type: typeof actionType.ISREFRESHING;
  payload: any;
}

export const fetchLocation = (data: any): FetchDataAction => {
  return {
    type: actionType.USER_LOCATION_FETCH,
    payload: data,
  };
};

export const refreshAction = (data: any) => {
  return {
    type: actionType.ISREFRESHING,
    payload: data,
  };
};
