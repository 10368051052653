import {
  Box,
  Button,
  Group,
  Select,
  Text,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { GlobalObjectInterface } from "../../../shared/interface";
import "react-phone-number-input/style.css";
import { useState } from "react";
import { inviteLink, updateInviteLink } from "../services/services";
import EmailTagInput from "./emailInput";
import toast, { Toaster } from "react-hot-toast";

interface Props {
  closeModal?: () => void;
  updateLinkSettings: (value: boolean) => void;
}

const AddUserModal = (props: Props) => {
  const { closeModal, updateLinkSettings } = props;
  const workspace_id = localStorage.getItem("workspace_id");
  const selectedWorkspace = JSON.parse(
    localStorage.getItem("selected_workspace") ?? "null"
  );
  const [selectedExpireValue, setSelectedExpireValue] = useState(selectedWorkspace?.invite_expire_in_days.toString() ?? "");
  const invite_id = selectedWorkspace?.invite_id;
  const [actionLoading, setActionLoading] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isLinkSettings, setIsLinkSettings] = useState(false);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);

  const form = useForm({
    initialValues: {
      emails: [],
    },
    validate: {
      emails: (value) => (value ? null : "Please add Email"),
    },
  });

  const resetApiCallSuccess = () => {
    setApiCallSuccess(false);
  };

  const openLinkSettings = () => {
    setIsLinkSettings(true);
    updateLinkSettings(true);
  };

  const closeLinkSettings = () => {
    setIsLinkSettings(false);
    updateLinkSettings(false);
  };

  const onRenew = async () => {
    try {
      setActionLoading(true);
      const formData = new FormData();
        formData.append("workspace_id", workspace_id);
        formData.append("renew_link", "True");
      const response:any = await updateInviteLink(formData);
      const storedWorkspace = JSON.parse(localStorage.getItem("selected_workspace") ?? "null");
      storedWorkspace.invite_expire_in_days = response?.new_invite_details?.expire_in_days;
      storedWorkspace.invite_url = response?.new_invite_details?.invite_url;
      localStorage.setItem("selected_workspace", JSON.stringify(storedWorkspace));
      setSelectedExpireValue(storedWorkspace.invite_expire_in_days.toString());
      toast.success("Link renewed");
      setActionLoading(false);
      console.log("success");
    } catch (error) {
      toast.error("Failed to renew link");
      console.error("Error during renew API call:", error);
      setActionLoading(false);
    }
  };

  const onExpiry = async () => {
    try {
      setActionLoading(true);
      const formData = new FormData();
      if (selectedExpireValue) {
        formData.append("workspace_id", workspace_id);
        formData.append("new_expire_in_days", selectedExpireValue);
      }
     const response:any =  await updateInviteLink(formData);
      toast.success("Link settings updated");
      setActionLoading(false);
      const storedWorkspace = JSON.parse(localStorage.getItem("selected_workspace") ?? "null");
      storedWorkspace.invite_expire_in_days = response?.new_invite_details?.expire_in_days;
      localStorage.setItem("selected_workspace", JSON.stringify(storedWorkspace));
      setSelectedExpireValue(storedWorkspace.invite_expire_in_days.toString());
    } catch (error) {
      toast.error("Failed to update link settings");
      console.error("Error during add expiry call:", error);
      setActionLoading(false);
    }
  };

  const onSubmit = (user: GlobalObjectInterface) => {
    const emails = form.values.emails;
    if (emails.length !== 0) {
      setActionLoading(true);
      const queryParams = {
        workspace_id,
        emails,
      };
      const formData = new FormData();
      formData.append("invite_id", invite_id);
      formData.append("emails", emails.join(","));
      inviteLink(formData, queryParams)
        .then((apiResponse) => {
          console.log("API response:", apiResponse);
          setActionLoading(false);
          toast.success("Send invite url via email");
          setApiCallSuccess(true);
        })
        .catch((error) => {
          console.error("Error during API call:", error);
          toast.error("Failed to send invite url");
        });
    } else {
      toast.error("Please add a valid email");
    }
  };

  const copyInviteLinkToClipboard = () => {
    const inviteLink = selectedWorkspace?.invite_url;
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink).then(
        () => {
          console.log("Invite link copied to clipboard");
          setIsLinkCopied(true);
          setTimeout(() => {
            setIsLinkCopied(false);
          }, 5000);
        },
        (error) => {
          console.error("Unable to copy invite link to clipboard", error);
        }
      );
    }
  };

  const handleEmailsChange = (emails) => {
    form.setFieldValue("emails", emails);
  };

  return (
    <Box>
      <LoadingOverlay
        visible={actionLoading}
        overlayBlur={2}
        loaderProps={{ variant: "bars" }}
      />
      <Toaster/>
      {isLinkSettings ? (
        <>
          <Select
            rightSection={<i className="bx bx-chevron-down" />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={[
              { value: "0", label: "Never" },
              { value: "7", label: "Expires in 7 days" },
              { value: "15", label: "Expires in 15 days" },
              { value: "30", label: "Expires in 30 days" },
            ]}
            value={selectedExpireValue}
            onChange={(selectedOption: any) => {
              setSelectedExpireValue(selectedOption);
            }}
            style={{ margin: "30px 0px 30px 0px" }}
          />
          <Group position="right" mt="md">
            <Button
              onClick={onRenew}
              bg="#005F2B"
              color="white"
              mr={"40px"}
            >
              Deactivate & Renew
            </Button>
            <Button onClick={closeLinkSettings} bg="none" variant="default">
              Back
            </Button>
            <Button onClick={onExpiry} variant="gradient" bg="#75B121">
              Save
            </Button>
          </Group>
        </>
      ) : (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <EmailTagInput
            onTagsChange={handleEmailsChange}
            clearOnSuccess={apiCallSuccess}
            resetApiCallSuccess={resetApiCallSuccess}
          />
          <Group position="right" mt="md">
            <Text
              color="#75B121"
              size={"sm"}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={copyInviteLinkToClipboard}
            >
              {isLinkCopied ? "Link copied!" : "Copy Invite Link"}
            </Text>
            <Text
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                marginRight: "20px",
              }}
              size={"sm"}
              onClick={openLinkSettings}
            >
              - Edit Link Settings
            </Text>
            <Button type="submit" bg={"#75B121"}>
              Send Invite
            </Button>
          </Group>
        </form>
      )}
    </Box>
  );
};
export default AddUserModal;
