import React from "react";
import { Button, Grid } from "@mantine/core";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import httpService from "../shared/services/http.service";
import useManageParameters from "../hooks/useManageParameters";
import useSaveParams from "../hooks/useSaveParams";

const LogoutPolicyButtons = () => {
  const navigate = useNavigate();

  useSaveParams();
  const { mParamFlag } = useManageParameters();
  // const mParamFlag = localStorage.getItem("mParam");

  const handleLogout = () => {
    httpService
      .post(
        "dashboard/logout",
        {},
        {},
        { Authorization: localStorage.getItem("authToken") }
      )
      .then((res) => {
        console.log(res);
        const mode = localStorage.getItem("mParam");
        console.log("mode", mode);
        localStorage.clear();
        if (mode !== undefined && mode !== null) {
          navigate("/?m=i_a");
        } else {
          navigate("/");
        }
        window.location.reload();
      });
  };

  return (
    <Grid gutter="xl" grow>
      <Grid.Col md={12} lg={12} style={{ marginTop: "130px" }}>
        <Button
          size="lg"
          variant="outline"
          color={"green"}
          fullWidth
          onClick={() => navigate("/workspaces/dashboard/policy")}
          h={"35px"}
        >
          Privacy Policy
        </Button>
      </Grid.Col>
      <Grid.Col md={12} lg={12}>
        {mParamFlag === "i_a" ? (
          <></>
        ) : (
          <Button
            size="lg"
            variant="outline"
            color={"gray"}
            fullWidth
            onClick={handleLogout}
            h={"35px"}
          >
            Logout
          </Button>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default LogoutPolicyButtons;
