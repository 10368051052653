import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@mantine/core";
import { Image } from "@mantine/core";
import SHAPE from "../../assets/images/Shape.png";
import { Calendar } from "@mantine/dates";

export const AttendenceCalenderMobile = ({
  onDateSelectMobile,
  passingDate,
}) => {
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = months[currentDate.getMonth()];
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);

  useEffect(() => {
    if (passingDate) {
      setSelectedDate(passingDate);
    }
  }, [passingDate]);

  useEffect(() => {
    onDateSelectMobile(currentDate);
  }, []);

  const handleApplyClick = () => {
    setIsPopoverOpen(false);
    onDateSelectMobile(selectedDate);
  };

  const handleCancelClick = () => {
    setIsPopoverOpen(false);
    setSelectedDate(null);
  };

  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleDayClick = (day) => {
    setSelectedDate(day);
  };

  const renderCalenderContent = () => {
    return (
      <>
        {isPopoverOpen && (
          <div className="custom-popover" onClick={handlePopoverClick}>
            <div className="attendence-calender-mobile">
              <Calendar
                allowLevelChange={false}
                renderDay={(day) => {
                  const isSelected =
                    selectedDate &&
                    day.toDateString() === selectedDate.toDateString();
                  return (
                    <div
                      className={`day-cell ${isSelected ? "selected" : ""}`}
                      onClick={() => handleDayClick(day)}
                    >
                      {day.getDate()}
                    </div>
                  );
                }}
                className="calender-new-mobile"
              />
            </div>
            <div className="popover-buttons">
              <Button
                onClick={handleCancelClick}
                style={{ backgroundColor: "#E7EAEE", color: "#101010" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleApplyClick}
                style={{ backgroundColor: "#75B121" }}
              >
                <Image src={SHAPE} width={12} height={12} />
                &nbsp; Apply
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className="calender-box-mobile"
        style={{ cursor: "pointer", padding: "10px", width: "100%" }}
      >
        <div
          className="selected-month-mobile"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          {passingDate ? (
            <div className="selected-month">{formatDate(passingDate)}</div>
          ) : selectedDate ? (
            <div className="selected-month">{formatDate(selectedDate)}</div>
          ) : (
            <div className="selected-month">{formatDate(currentDate)}</div>
          )}
        </div>
        {renderCalenderContent()}
      </div>
    </>
  );
};

AttendenceCalenderMobile.propTypes = {
  onDateSelectMobile: PropTypes.func.isRequired,
  passingDate: PropTypes.instanceOf(Date),
};
