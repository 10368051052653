import { Box, Text } from "@mantine/core";
import { FC } from "react";

interface props {
  status?: string | number;
  users?: string | number;
}
const chipStyle = {
  width: "max-content",
  padding: "4px",
  borderRadius: "5px",
  backgroundColor: "white",
  border: "1px solid #e7e4e4",
};
export const StatusChip: FC<props> = ({ status }) => {
  return (
    <Box sx={{ ...chipStyle, paddingLeft: "5px", paddingRight: "5px" }}>
      <Text sx={{ color: "black" }}>Status - {status}</Text>
    </Box>
  );
};

export const UsersChip: FC<props> = ({ users }) => {
  return (
    <Box sx={{ ...chipStyle, paddingLeft: "10px", paddingRight: "10px" }}>
      <Text sx={{ color: "black" }}>Users - {users}</Text>
    </Box>
  );
};
