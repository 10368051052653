import {
  ActionIcon,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  Flex,
  Image,
  List,
  Modal,
  Table,
  Text,
} from "@mantine/core";
import { Key, useState } from "react";
import { Download, Flask } from "tabler-icons-react";
import "../style/roomHistoryStyle.css";
interface Props {
  medialink: string;
  messageatlast: string;
  from: string;
  callDur: any;
  description: any;
  children: any;
  to: string;
  handleDelete: any;
  attachment: any;
}

export default ChannelHistoryList;
function ChannelHistoryList({
  medialink,
  messageatlast,
  from,
  callDur,
  description,
  children,
  to,
  attachment,
  handleDelete,
}: Props) {
  const timeFormat = new Date(callDur * 1000).toISOString().slice(11, 19);
  const imageUrl =
    attachment.images !== undefined ? attachment.images[0].url : "";
  const wordBreakLimit = 50;

  const [details, setDetails] = useState(
    description === null
      ? description
      : description.length > wordBreakLimit
      ? description.slice(0, wordBreakLimit)
      : description
  );
  const [discriptionModalOpen, setDiscriptionModalOpen] = useState(false);
  const [attachmentListModalOpen, setAttachmentListModalOpen] = useState(false);
  const [isImgView, setImgView] = useState(false);

  const handleImageView = () => {
    setImgView(true);
  };

  const renderDescriptionModal = () => {
    return (
      <Modal
        overflow="inside"
        withCloseButton={true}
        styles={{
          header: {
            margin: "0",
            fontWeight: "bold",
          },
        }}
        opened={discriptionModalOpen}
        onClose={() => setDiscriptionModalOpen(false)}
        title="Description"
      >
        <Card m={0} p={0}>
          <Table>
            <thead>
              <tr>
                <th style={{ padding: "1px" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box>
                    <Flex wrap={"wrap"}>
                      <Text>{description}</Text>
                    </Flex>
                  </Box>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Modal>
    );
  };

  const renderDownloadIcon = (medialink: string) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ActionIcon title="download" color={"dark"} variant="transparent">
          <a
            href={medialink}
            target="_blank"
            rel="noreferrer"
            download={true}
            style={{ color: "ButtonText" }}
            onClick={(e: any) => {
              return false;
            }}
          >
            <Download />
          </a>
        </ActionIcon>
      </div>
    );
  };

  const renderDeleteButton = () => {
    return (
      <Button
        title="Remove from channel"
        variant="filled"
        color={"red"}
        px={"6px"}
        size="xs"
        onClick={handleDelete}
      >
        <i className="bx bx-trash bx-xs" />
      </Button>
    );
  };

  const renderViewModal = () => {
    if (attachment.images !== undefined) {
      return (
        <Modal
          onClose={() => setAttachmentListModalOpen(false)}
          opened={attachmentListModalOpen}
          color={"yellow"}
          title="Attachments"
          overflow={"inside"}
          styles={{
            header: {
              margin: "0",
              fontWeight: "bold",
            },
          }}
        >
          <Card p={0}>
            <Box>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
              </Table>
              <List>
                {attachment.images.map((img: { url: string }, ind: Key) => {
                  const label = img.url.split("/")[4];
                  return (
                    <List.Item
                      key={ind}
                      style={{
                        marginInline: "15px",
                        paddingBlock: "10px",
                      }}
                    >
                      <Modal
                        centered
                        opened={isImgView}
                        onClose={() => setImgView(false)}
                        classNames={{
                          modal: "image-modal",
                          close: "close-modal",
                          header: "modal-header",
                          inner: "modal-inner",
                          root: "modal-root",
                          body: "modal-body",
                        }}
                      >
                        <Image src={img.url} width={300} height={'auto'} />
                      </Modal>

                      <a
                        href="#javascript"
                        onClick={handleImageView}
                        style={{ cursor: "pointer" }}
                      >
                        {label}
                      </a>
                    </List.Item>
                  );
                })}
              </List>
            </Box>
          </Card>
        </Modal>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderDescriptionModal()}
      <tr>
        <td style={{ marginInline: "10px" }}>
          <Text className="hide-in-mobile">{messageatlast}</Text>
          <div className="show-in-mobile-only">
            <div className="mobile-view-history">
              <div>
                <Text>
                  <b>Date & Time:</b> {messageatlast}
                </Text>
                <Text>
                  <b>From:</b> {from}
                </Text>
              </div>
              <div>
                <Text>
                  <b>To:</b> {to}
                </Text>
              </div>
              {description !== null && (
                <div
                  style={{
                    wordBreak: "break-word",
                    overflow: "hidden",
                    marginTop: "4px",
                  }}
                >
                  <b> Description:</b> {details}
                  {description.length > wordBreakLimit && (
                    <Button
                      variant="subtle"
                      size="xs"
                      m={0}
                      p={5}
                      onClick={() => setDiscriptionModalOpen(true)}
                      className={"PopBtn"}
                    >
                      Read more...
                    </Button>
                  )}
                </div>
              )}
              <div className="date-box">
                <div className="display-bar">
                  {children}
                  {timeFormat}
                </div>
                <div className="display-bar">
                  {renderDownloadIcon(medialink)}
                </div>
                {attachment.images && attachment.images.length > 0 && (
                  <div>
                    <Button
                      onClick={() => setAttachmentListModalOpen(true)}
                      color={"yellow"}
                      className="display-attachment"
                    >
                     <i className="bx bx-show" style={{ fontSize: "18px" }} />
                      &nbsp; Attachment
                    </Button>
                    {renderViewModal()}
                  </div>
                )}
                <div>{renderDeleteButton()}</div>
              </div>
            </div>
          </div>
        </td>
        <td
          width={300}
          style={{
            wordBreak: "break-word",
            overflow: "hidden",
          }}
          className="hide-in-mobile"
        >
          {description === null ? (
            ""
          ) : description.length > wordBreakLimit ? (
            <>
              {description}
              <Button
                variant="subtle"
                size="xs"
                m={0}
                p={5}
                onClick={() => setDiscriptionModalOpen(true)}
                className={"PopBtn"}
              >
                Read more...
              </Button>
            </>
          ) : (
            description
          )}
        </td>
        <td className="hide-in-mobile" width={100}>
          <Text>{from}</Text>
        </td>
        <td className="hide-in-mobile">{to}</td>
        <td style={{ padding: "0" }} className="hide-in-mobile">
          {children}
        </td>
        <td className="hide-in-mobile">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {timeFormat}
          </div>
        </td>
        <td width={125} className="hide-in-mobile">
          {renderDownloadIcon(medialink)}
        </td>
        <td className="hide-in-mobile">
          {renderViewModal()}
          {attachment.images === undefined ? (
            ""
          ) : (
            <Button
              onClick={() => setAttachmentListModalOpen(true)}
              color={"yellow"}
            >
              View
            </Button>
          )}
        </td>
        <td width={100} className="hide-in-mobile">
          {renderDeleteButton()}
        </td>
      </tr>
    </>
  );
}
