import { API_CONFIG } from "../../../constant/constant";
import {
  GlobalObjectInterface,
  ChannelInterface,
  UserInterface,
} from "../../../shared/interface";
import HttpService from "../../../shared/services/http.service";

export const fetchChannels = (params: any, find = "") => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.channelList, params)
      .then(({ channels }: GlobalObjectInterface) => {
        if (find) {
          const foundChannelIndex: number = channels.findIndex(
            (channel: ChannelInterface) =>
              channel.channel_id === (find as string)
          );

          const foundChannel: ChannelInterface = channels[foundChannelIndex];
          resolve({
            room: foundChannel,
            roomIndex: foundChannelIndex,
            roomName: foundChannel.group_name,
            roomStatus: !!foundChannel.live,
          });
        } else {
          resolve(channels);
        }
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const updateChannel = (params: any) => {
  const formData = new FormData();
  for (const key in params) {
    const element = params[key];
    formData.append(key, element);
  }
  return new Promise((resolve, reject) => {
    HttpService.put(
      API_CONFIG.path.updateChannel,
      formData,
      {},
      {
        contentType: "multipart/form-data",
      }
    )
      .then((response: GlobalObjectInterface) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};

export const createChannel = (params: any) => {
  const formData = new FormData();
  for (const key in params) {
    const element = params[key];
    formData.append(key, element);
  }
  return new Promise((resolve, reject) => {
    HttpService.post(
      API_CONFIG.path.createChannel,
      formData,
      {},
      {
        contentType: "multipart/form-data",
      }
    )
      .then((response: GlobalObjectInterface) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
