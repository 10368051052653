import { resolve } from "path";
import { API_CONFIG } from "../../../constant/constant";
import { GlobalObjectInterface } from "../../../shared/interface";
import HttpService from "../../../shared/services/http.service";
export const deleteWorkspaceReq = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.deleteRequest(API_CONFIG.path.fetchWorkSpaces, {}, params)
      .then(({ workspace_id }: GlobalObjectInterface) => {
        resolve(workspace_id);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
export const fetchUserCount = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.get(API_CONFIG.path.usercount, params)
      .then(({ active_members }: GlobalObjectInterface) => {
        resolve(active_members);
      })
      .catch((error) => {
        console.log("Error : ", error);
        reject(error);
      });
  });
};
export const workspaceActivateReq = (params: any) => {
  return new Promise((resolve, reject) => {
    HttpService.patch(API_CONFIG.path.fetchWorkSpaces, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
