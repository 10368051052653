import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Tabs,
  Box,
  Card,
  Grid,
  useMantineTheme,
  Select,
  Button,
  Table,
  Avatar,
  Text,
  TextInput,
  Loader,
  Center,
  Image,
  Divider,
} from "@mantine/core";
import CustomModal from "../../../component/common/modal";
import {
  GlobalObjectInterface,
  UserInterface,
} from "../../../shared/interface";
import { fetchUserList, updateUser } from "../services/services";
import { debounce } from "../../../shared/utility";
import { inviteLink } from "../../users/services/services";
import { StatusChip } from "../../shared components/statusChip";
import INVITE from "../../../assets/images/invite.png";
import AddUserModal from "../component/addUserModal";
import UpgradePlanModal from "../component/upgradePlanModal";
import { Toaster } from "react-hot-toast";
import { API_CONFIG } from "../../../constant/constant";
import axios from "axios";
import useSaveParams from "../../../hooks/useSaveParams";
import useManageParameters from "../../../hooks/useManageParameters";

const tabs = {
  teamMember: "Team Member",
};

export default function Users() {
  useSaveParams();
  const { mParamFlag: mParam, workspace_id, authToken } = useManageParameters();

  const currentUser = JSON.parse(localStorage.getItem("current_user")) || {};
  const name = currentUser?.name;
  const mobile_no = currentUser?.mobile_no;
  // const { name, mobile_no } = JSON.parse(localStorage.getItem("current_user"));
  const localStorageSelectedWorkSpace =
    localStorage.getItem("selected_workspace");
  const selectedWorkspace =
    localStorageSelectedWorkSpace !== "undefined" &&
    localStorageSelectedWorkSpace !== null
      ? JSON.parse(localStorageSelectedWorkSpace)
      : null;
  const [showUpgradeButton, setShowUpgradeButton] = useState(
    selectedWorkspace?.plan_id === null
  );
  useEffect(() => {
    setShowUpgradeButton(selectedWorkspace?.plan_id === null);
  }, [selectedWorkspace]);

  const [users, setUsers] = useState<UserInterface[]>([]);
  const [userLocation, setUserLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSettings, setIsLinkSettings] = useState(false);
  const [search, setSearch] = useState("");
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  // const queryParams = new URLSearchParams(location.search);
  const [isOpenedWithLink, setIsOpenedWithLink] = useState(
    mParam !== null && mParam !== undefined
  );
  const updateLinkSettings = (value: boolean) => {
    setIsLinkSettings(value);
  };

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
  };

  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  const func: any = useCallback(
    debounce((string: string) => fetchUsers(string)),
    []
  );

  useEffect(() => {
    (search !== null || search !== undefined) && func(search);
  }, [search]);

  const changeHandler = (e: any) => {
    const { value } = e.target;
    setSearch(value);
  };

  const [actionLoading, setActionLoading] = useState(false);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string | null>("teamMember");
  const theme = useMantineTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (workspace_id) {
      fetchUsers();
    }
  }, [workspace_id]);

  const generateQueryUrl = (
    workspaceId: string,
    page: number,
    search: string
  ): string => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/${API_CONFIG.path.user}`;
    const queryParams = new URLSearchParams();

    // Add workspace_id parameter
    if (workspaceId) {
      queryParams.append("workspace_id", workspaceId);
    }

    // Add page parameter
    if (page) {
      queryParams.append("page", String(page));
    }

    // Add search parameter
    if (search) {
      queryParams.append("search", search);
    }

    const queryString = queryParams.toString();

    // Append query string to base URL
    const queryUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    return queryUrl;
  };

  const fetchUsers = async (search = "") => {
    setIsLoading(true);
    // const authToken = getAuthToken();
    if (workspace_id && authToken) {
      const params: GlobalObjectInterface = {
        page: 1,
        workspace_id: workspace_id,
      };

      if (search) {
        params.search = search;
      }

      const queryUrl = generateQueryUrl(workspace_id, 1, search);

      try {
        const response: any = await axios.get(queryUrl, {
          headers: {
            Authorization: authToken,
          },
        });
        const retrievedUsers = response?.data?.data?.users;
        setUsers(retrievedUsers);
        setIsLoading(false);
      } catch (error) {
        console.error("Error making GET request:", error);
        setIsLoading(false);
      }

      // const res = await fetch(process.env.REACT_APP_BASE_URL)

      // fetchUserList(params)
      //   .then((users: any) => {
      //     setIsLoading(false);
      //     setUsers(users);
      //   })
      //   .catch((error) => {
      //     console.log("Error : ", error);
      //     // window.location.reload();
      //     setIsLoading(false);
      //   });
      //   setIsLoading(false)
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsLinkSettings(false);
  };

  const statusColorMapper: GlobalObjectInterface = {
    Online: "lime.9",
    Offline: "red.7",
  };

  const renderRoleDropdown = (element, index) => {
    const handleRoleChange = (value: string, user_id: string) => {
      const field = "role";
      const workspace_id = localStorage.getItem("workspace_id");

      updateUser({
        [field]: value,
        workspace_id,
        workspace_user_id: user_id,
      })
        .then((response) => {
          console.log("Update successful: ", response);
          const usersCloned = [...users];
          usersCloned[index] = {
            ...usersCloned[index],
            role: value,
          };
          setUsers(usersCloned);
        })
        .catch((error) => {
          console.error("Update failed: ", error);
        });
    };

    return (
      // <div>{element.role}</div>
      <Select
        disabled={
          element.name == name &&
          element.mobile_no === mobile_no
          // Number(element.mobile_no) === Number(mobile_no)

        }
        className="role-dropdown"
        onChange={(selectedRole) => {
          if (selectedRole) {
            handleRoleChange(selectedRole, element.id);
          }
        }}
        rightSection={<i className="bx bx-chevron-down" />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
        data={[
          { value: "admin", label: "Admin" },
          { value: "member", label: "Member" },
        ]}
        placeholder="Role"
        value={element.role}
        variant="unstyled"
      />
    );
  };

  const renderViewIcon = (element) => {
    return (
      <Button
        title="View"
        variant="filled"
        color={"green.8"}
        px={"5px"}
        pt="3px"
        size="xs"
        onClick={() => {
          navigate(`${element.id}`);
        }}
      >
        <i className="bx bx-show bx-sm" />
      </Button>
    );
  };

  const rows = users.map((element, index) => {
    return (
      <tr key={element.id}>
        <td>
          <Grid my={"2px"}>
            <Grid.Col span={"content"}>
              <Avatar
                src={
                  element.profile_pic ||
                  "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                }
                onClick={() => {
                  navigate(`${element.id}`);
                }}
                alt="User Profile"
                className="avathar-box"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <div className="mobile-no-box">
                <Text
                  weight={"bold"}
                  size={"lg"}
                  onClick={() => {
                    navigate(`${element.id}`);
                  }}
                >
                  {element.name == name &&
                element.mobile_no === mobile_no
                  // Number(element.mobile_no) === Number(mobile_no)

                    ? `you`
                    : element.name}
                </Text>
                <div className="show-in-mobile-only">
                  <span
                    className={`online-status-dot ${
                      element.online ? "online" : "offline"
                    }`}
                  ></span>
                </div>
              </div>
              <Text size={"sm"}>{element.email || "-"}</Text>
              <Text size={"sm"}>{element.mobile_no || "-"}</Text>
            </Grid.Col>
          </Grid>
          <div className="show-in-mobile-only">
            <div className="status-chip">
              <StatusChip status={element.status} />
              <div>{renderRoleDropdown(element, index)}</div>
              <div className="view-icon">{renderViewIcon(element)}</div>
            </div>
          </div>
        </td>

        <td className="hide-in-mobile">{String(element.online)}</td>
        <td className="hide-in-mobile" style={{ width: "20%" }}>
          {renderRoleDropdown(element, index)}
        </td>
        <td className="hide-in-mobile">
          <Text
            my={"xs"}
            size={"sm"}
            color={statusColorMapper[element.status as string]}
          >
            {element.status}
          </Text>
        </td>
        <td className="hide-in-mobile">{renderViewIcon(element)}</td>
      </tr>
    );
  });

  return (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
    >
      <Toaster toastOptions={{ duration: 3000 }} />
      <CustomModal
        open={isModalOpen}
        className="invite-users-modal"
        centered
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ color: "#75B121" }}>
              {isLinkSettings ? "Link Settings" : "Invite New User"}
            </h3>
          </div>
        }
        closeModal={toggleModal}
      >
        <hr
          style={{
            width: "100%",
            backgroundColor: "green",
            height: "1px",
            border: "none",
          }}
        />
        <AddUserModal
          closeModal={toggleModal}
          updateLinkSettings={updateLinkSettings}
        />
      </CustomModal>
      <CustomModal
        open={isUpgradeModalOpen}
        className="upgrade-modal"
        centered
        closeModal={closeUpgradeModal}
      >
        <UpgradePlanModal
          showUpgradeButton={showUpgradeButton}
          openUpgradeModal={openUpgradeModal}
          closeUpgradeModal={closeUpgradeModal}
        />
      </CustomModal>
      <Box>
        <Tabs color="lime.9" value={activeTab} onTabChange={setActiveTab}>
          <Grid gutter={0} align="flex-end">
            <Grid.Col md={6} lg={6}>
              <Tabs.List>
                <Tabs.Tab
                  value="teamMember"
                  icon={<i className="bx bx-group" />}
                  style={{ fontWeight: "bold" }}
                >
                  {tabs["teamMember"]}
                </Tabs.Tab>
              </Tabs.List>
            </Grid.Col>
            <Grid.Col
              md={6}
              lg={6}
              style={{
                borderBottom: `2px solid ${
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[4]
                    : theme.colors.gray[3]
                }`,
              }}
            >
              <Grid my={"2px"} gutter={"xs"}>
                <Grid.Col sm={6} md={8} lg={6}>
                  <TextInput
                    placeholder="Search Users"
                    value={search}
                    onChange={changeHandler}
                    icon={<i className="bx bx-search-alt" />}
                  />
                </Grid.Col>
                <Grid.Col sm={6} md={4} lg={3}>
                  <Button
                    bg={"#75B121"}
                    fullWidth
                    onClick={() => {
                      if (isOpenedWithLink) {
                        if (selectedWorkspace?.free_to_invite) {
                          toggleModal();
                        } else {
                          openUpgradeModal();
                        }
                      } else {
                        if (selectedWorkspace?.free_to_invite) {
                          toggleModal();
                        } else {
                          openUpgradeModal();
                        }
                      }
                    }}
                  >
                    <Image src={INVITE} width={14} height={14} mr={"10px"} />
                    Invite User
                  </Button>
                </Grid.Col>
                <Grid.Col sm={6} md={4} lg={3}>
                  {showUpgradeButton && !isOpenedWithLink && (
                    <Button bg={"#75B121"} fullWidth onClick={openUpgradeModal}>
                      <Image src={INVITE} width={14} height={14} mr={"10px"} />
                      Subscribe
                    </Button>
                  )}
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          <Tabs.Panel value="teamMember" mt={"lg"}>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="hide-in-mobile">Online</th>
                  <th className="hide-in-mobile">Role</th>
                  <th className="hide-in-mobile">Status</th>
                  <th className="hide-in-mobile">Actions</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                        <Loader variant="bars" />
                      </Center>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>{rows}</tbody>
              )}
            </Table>
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Card>
  );
}
