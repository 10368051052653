import actionType from "./actionType";
import intialData from "./intialData";

function userWorkSpaceReducer(
  state = intialData,
  action: {
    payload: any;
    type: string;
  }
) {
  if (actionType.USER_WORKSPACE_FETCH === action.type) {
    return { ...action.payload };
  } else if (actionType.USER_WORKSPACE_ACTIVE === action.type) {
    return { workspaces: [...action.payload] };
  } else if (actionType.ERROR === action.type) {
    return { ...state, error: { ...action.payload } };
  } else {
    return { ...state };
  }
}
export default userWorkSpaceReducer;
