import React, { useState, useEffect } from "react";
import TimeZoneSelect, { ITimezoneOption } from "react-timezone-select";
import PropTypes from "prop-types";

const TimeZoneDropdown = ({ onTimeZoneChange }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone(userTimeZone);
    onTimeZoneChange(userTimeZone);
  }, []);

  const handleTimeZoneChange = (value: ITimezoneOption) => {
    setSelectedTimeZone(value.value);
    onTimeZoneChange(value.value);
  };

  return (
    <div className="time-zone">
      <TimeZoneSelect
        value={selectedTimeZone}
        onChange={handleTimeZoneChange}
      />
    </div>
  );
};

export default TimeZoneDropdown;
TimeZoneDropdown.propTypes = {
  onTimeZoneChange: PropTypes.func.isRequired,
};
