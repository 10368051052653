import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mantine/core';

const CustomOtpInput = ({ length, onChange ,onInvalidOtp}) => {
  const [otpValues, setOtpValues] = useState(Array(length).fill(''));
  const inputRefs = useRef(Array(length).fill(null));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (onInvalidOtp) {
      setOtpValues(Array(length).fill(''));
      inputRefs.current[0]?.focus();
    }
  }, [onInvalidOtp, length]);

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
    onChange(newOtpValues.join(''));
  };

  return (
    <Box style={{ display: 'flex' }}>
      {otpValues.map((value, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleInputChange(index, e.target.value)}
          style={{
            width: '2.5em',
            height: '2.5em',
            fontSize: '1em',
            textAlign: 'center',
            margin: '0 0.2em',
            border: '1px solid #75B121',
            borderRadius: '5px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
            outline: 'none',
            color:"#75B121"
          }}
        />
      ))}
    </Box>
  );
};

CustomOtpInput.propTypes = {
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onInvalidOtp: PropTypes.bool.isRequired,
};

export default CustomOtpInput;
