import actionType from "./actionType";

interface FetchDataAction {
  then(arg0: (data: any) => void): unknown;
  type: typeof actionType.USER_WORKSPACE_FETCH;
  payload: any;
}

export const fetchWorkSpaces = (data: any) => {
  return {
    type: actionType.USER_WORKSPACE_FETCH as string,
    payload: data as any,
  };
};

export const workspaceStatus = (data: any) => {
  return {
    type: actionType.USER_WORKSPACE_ACTIVE as string,
    payload: data as any,
  };
};
export const LoadingStatus = (data: any) => {
  return {
    type: actionType.WORKSPACE_LOADING as string,
    payload: data as boolean,
  };
};
export const error = (data: any) => {
  console.log("error");
  return {
    type: actionType.ERROR as string,
    payload: data as boolean,
  };
};
