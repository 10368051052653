import { lazy, Suspense, useEffect, useState } from "react";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  LoadingOverlay,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { useColorScheme } from "@mantine/hooks";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./hoc/layout";

import Dashboard from "./features/dashboard/container/dashboard";
import Home from "./features/home/component/home";

import Profile from "./features/profile/container/profile";
import Users from "./features/users/container/users";
import Rooms from "./features/rooms/container/rooms";

import ChannelLocations from "./features/roomLocation/container/roomLocation";
import ChannelSettings from "./features/roomSettings/container/roomSettings";

import "./App.scss";
import { withRouter } from "./hoc/withRouter";
import NAV_OPTIONS from "./constant/nav";
import { NotificationsProvider } from "@mantine/notifications";
import ChannelHistory from "./features/roomHistory/container/roomHistory";

import Policy from "./features/Policy";
import AttendenceDashboard from "./features/attendence/container/attendenceDashboard";
import WorkspaceList from "./features/workspaces/container/workspaceLists";
import PlanAndBilling from "./features/planAndBilling/components/planAndBilling";
import PaddlePayment from "./features/planAndBilling/components/PaddlePayment";
import InitialLogic from "./initialLogic";

const UsersDetail = withRouter(
  lazy(() => import("./features/users/container/userDetail"))
);
const RoomDetail = withRouter(
  lazy(() => import("./features/rooms/container/roomDetail"))
);

function App() {
  // hook will return either 'dark' or 'light' on client
  // and always 'light' during ssr as window.matchMedia is not available
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] =
    useState<ColorScheme>(preferredColorScheme);
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const [isLoading, setIsLoading] = useState(true); // Get loading state from InitialLogic

  useEffect(() => {
    // You can fetch additional data specific to App.tsx here if needed
  }, [isLoading]); // Optional dependency on isLoading for App-specific logic

  return (
    <div>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            colorScheme,
            defaultGradient: { from: "#557b32", to: "#88b664", deg: 20 },
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <NotificationsProvider>
            <ModalsProvider>
              <BrowserRouter>
                <Suspense
                  fallback={
                    <LoadingOverlay
                      visible={true}
                      overlayBlur={2}
                      loaderProps={{ variant: "bars" }}
                    />
                  }
                >
                  {/* <Suspense fallback={<div>Loading...</div>}> */}
                  <Routes>
                    <Route path={"/"} key={"0"} element={<Home />} />
                    <Route path={"/payment"} element={<PaddlePayment />} />
                    <Route path={"/workspaces"} element={<WorkspaceList />} />
                    <Route path={"/workspaces/dashboard"} element={<Layout />}>
                      {/* <Route
                        path={NAV_OPTIONS.DASHBOARD.path}
                        element={<Dashboard />}
                      /> */}
                      {/* <Route
                        path={`${NAV_OPTIONS.PROFILE.path}`}
                        element={<Profile />}
                      /> */}
                      <Route
                        path={NAV_OPTIONS.USERS.path}
                        key={"1"}
                        element={<Users />}
                      />
                      <Route
                        path={`${NAV_OPTIONS.USERS.path}/:id`}
                        element={<UsersDetail />}
                      />
                      <Route
                        path={`${NAV_OPTIONS.ATTENDANCE.path}`}
                        element={<AttendenceDashboard />}
                      />
                      <Route
                        path={NAV_OPTIONS.CHANNELS.path}
                        element={<Rooms />}
                      />
                      <Route
                        path={`${NAV_OPTIONS.CHANNELS.path}/:id`}
                        element={<RoomDetail />}
                      />

                      <Route
                        path={NAV_OPTIONS.HISTORY.path}
                        element={<ChannelHistory />}
                      />
                      <Route
                        path={NAV_OPTIONS.LOCATION.path}
                        element={<ChannelLocations />}
                      />
                      <Route
                        path={NAV_OPTIONS.SETTINGS.path}
                        element={<ChannelSettings />}
                      />
                      <Route
                        path={NAV_OPTIONS.PLAN.path}
                        element={<PlanAndBilling />}
                      />
                      <Route path={"policy"} element={<Policy />} />
                    </Route>
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </div>
  );
}

export default App;
