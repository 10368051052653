import React, { ReactNode, useState } from 'react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    width: string;
    height: string;
    children: ReactNode; // This is the updated type definition for children
    title: string; // Add a title prop
    value: string;

  }

export const LocationModal : React.FC<ModalProps> = ({ isOpen, onClose, width, height ,children,  title,value
}) => {
    if (!isOpen) return null;
  
    return (
        <div className="modal-overlay">
          <div className="modal" style={{ width, height }}>
            <div className="modal-header">
              <div className='mobile-no-box'>
                <h3>{title}</h3> &nbsp;{value}
                </div> 
              <button className="modal-close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    fill="black"
                    d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11 18 6.41z"
                  />
                </svg>
              </button>
            </div>
            {children}
          </div>
        </div>
      );
  };