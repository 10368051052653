import {
  Avatar,
  Box,
  Card,
  Center,
  Flex,
  Input,
  Overlay,
  Table,
  Text,
  Loader,
  Container,
  Button,
} from "@mantine/core";
import locationStyle from "../style/locationStyle.module.css";
import { CSSProperties, useEffect, useMemo, useState } from "react";

import MapComponent from "../../../component/common/map";
import LocationMarker from "./locationMarker";
import SearchBox from "./searchBox";
import { useSelector } from "react-redux";
import { Search } from "tabler-icons-react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionOfLoc } from "../redux/reducers";
import moment from "moment";

const containerStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  borderRadius: "7px",
  minHeight: "730px",
  maxHeight: "1000px",
};

const ChannelLocations = () => {
  const [center, setCenter] = useState<any>({ lat: 23.0225, lng: 72.5714 });
  const [zoom, setZoom] = useState(14);
  const dispatch = useDispatch();
  const { fetchLocationData, handleRefreshing } = bindActionCreators(
    actionOfLoc,
    dispatch
  );

  const [clickedUser, setClickedUser] = useState<any>({});
  const defaultZoom = useMemo(() => {
    return zoom;
  }, [zoom]);
  const [search, setSearch] = useState("");

  const [workspace, setWorkspace] = useState<any>({});
  const [isLoading, setLoading] = useState(true);
  const handleRefresh = () => {
    handleRefreshing(true);
    fetchLocationData(true);
  };
  const allUsersLocation = useSelector(
    (userLoc: any) => userLoc.userLocationReducer
  );
  const isRefresh = useSelector(
    (userLoc: any) => userLoc.userLocationReducer.isRefreshing
  );
  const locatedUsers = allUsersLocation.users.filter((user) => {
    if (user.location !== null) {
      return user;
    }
  });

  const userLoc = search
    ? locatedUsers
        .filter((user: any) =>
          user?.name?.toLowerCase().includes(search.toLowerCase())
        )
        .sort(
          (a: any, b: any) =>
            clickedUser?.id &&
            (a.id === clickedUser.id ? -1 : b.id === clickedUser.id ? 1 : 0)
        )
    : locatedUsers.sort(
        (a: any, b: any) =>
          clickedUser?.id &&
          (a.id === clickedUser.id ? -1 : b.id === clickedUser.id ? 1 : 0)
      );

  const allWorkspace = useSelector((workspace: any) => {
    return workspace.userWorkSpaceReducer.workspaces;
  });

  useEffect(() => {
    const workspace_id = localStorage.getItem("workspace_id");
    if (
      (allWorkspace.length > 0 && workspace_id === null) ||
      workspace_id === "undefined"
    ) {
      localStorage.setItem("workspace_id", allWorkspace[0].id);
    }
    if (allWorkspace.length > 0) {
      const workspaceObj = allWorkspace.find((wrkspc: any) => {
        const workspaceId = localStorage.getItem("workspace_id");
        if (wrkspc.id === workspaceId) {
          return wrkspc;
        } else {
          null;
        }
      });
      setWorkspace(workspaceObj);
      setLoading(false);
    }
  }, [allWorkspace]);

  return isLoading ? (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{
        textAlign: "center",
      }}
    >
      <table style={{ width: "100%" }}>
        <tbody>
          <tr
            style={{
              width: "100%",
              display: "flex",
              height: "100%",
              minHeight: "75vh",
              justifyContent: "center",
            }}
          >
            <td style={{ display: "flex", justifyContent: "center" }}>
              <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                <Loader variant="bars" />
              </Center>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  ) : (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{ textAlign: "left", minHeight: "calc(100vh - 180px)" }}
    >
      <Box style={{ position: "relative" }}>
        {!workspace.active ? <Overlay color={"black"} blur={3} /> : null}
        <MapComponent
          containerStyle={containerStyle}
          center={center}
          zoom={defaultZoom}
        >
          {clickedUser.id && (
            <LocationMarker
              Center={center}
              isLive={clickedUser.online ? false : true}
              imgPath={
                clickedUser.profile_pic
                  ? clickedUser.profile_pic
                  : "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
              }
            />
          )}

          {/* {userLoc.map((user: any, ind: number) => {
            const loc = user.location?.split(",");
            const lat = loc[0];
            const lng = loc[1];
            return (
              <LocationMarker
                Center={{
                  lat: lat,
                  lng: lng,
                }}
                key={ind}
                isLive={user.online ? false : true}
                imgPath={
                  user.profile_pic
                    ? user.profile_pic
                    : "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                }
              />
            );
          })} */}
          <SearchBox onRefresh={handleRefresh} refreshVal={isRefresh}>
            <Input
              variant="filled"
              height={50}
              placeholder="User's Location"
              icon={<Search style={{ width: "fit-content" }} />}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
            <Card
              className={locationStyle.scroll}
              style={{
                height: "100%",
                maxHeight: "206px",
                overflow: "scroll",
              }}
            >
              <Table>
                <tbody>
                  {userLoc.map((user: any, ind: number) => {
                    const loc = user.location.split(",");
                    return (
                      <tr key={ind}>
                        <td
                          style={{
                            cursor: "pointer",
                            borderRadius: "5px",
                            backgroundColor:
                              clickedUser?.id && clickedUser.id === user.id
                                ? "#d0e7ff "
                                : "",
                          }}
                          onClick={() => {
                            setClickedUser(user);
                            setZoom(8);
                            if (loc) {
                              setCenter({
                                lat: JSON.parse(loc[0]),
                                lng: JSON.parse(loc[1]),
                              });
                            }
                          }}
                        >
                          <Flex mih={50} align="center">
                            <Avatar
                              radius={100}
                              mr={20}
                              src={
                                user.profile_pic
                                  ? user.profile_pic
                                  : "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                              }
                            />
                            <Box>
                              <Text
                                fz={"xs"}
                                fw={"bold"}
                                color={user.online ? "green" : "dark"}
                              >
                                {user.online
                                  ? "Online"
                                  : user.location_updated_at}
                              </Text>
                              <Text fz={"md"}>{user.name}</Text>
                              <Text fz={"xs"}>
                                last updated{" "}
                                {moment(
                                  user.location_updated_at,
                                  "DD-MM-YYYY HH:mm:ss GMT Z"
                                ).fromNow()}
                              </Text>
                            </Box>
                          </Flex>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </SearchBox>
        </MapComponent>
      </Box>
    </Card>
  );
};
export default ChannelLocations;
